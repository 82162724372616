
/*
 *   File : add-bank-upi-verification.js
 *   Author URI : https://evoqins.com
 *   Description : Modal component for upi verification
 *   Integrations : null
 *   Version : v1.1
 */
import { useState, useEffect } from 'react';
import Icon from '../../Icon/icon';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { GradientButton, OutlineButton } from '../../Cta';
import { CustomLoader } from '../../Other';

import APIService from '../../../Services/api-service';

import style from '../../../Styles/Components/add-bank.module.scss';
import Colors from '../../../Styles/color.module.scss';

export default function AddBankUPIVerification(props) {

    let timer = 0;
    const location = useLocation();
    const [loader, setLoader] = useState(true);
    const [simulationType, setSimulationType] = useState(true);
    
    useEffect(() => {
        if (props.data !== null) {
            setLoader(false);
        }
    }, [props.data]);

    // Directly checking add bank status via upi if production
    useEffect(()=>{
        if (process.env.REACT_APP_ENV === 'PROD') {
            _callCheckUPIStatusAPI();
        }
    },[]);

    // Clearing timer when unmounting the Qr code
    useEffect(() => {
        return () => {
            clearInterval(timer);
        };
    }, []);

    function _callCheckUPIStatusAPI() {
        timer = setInterval(()=>{
            _checkUPIStatus();
        },3000);
    }

    // API :: payment simulation
    function _paymentSimulation(status) {
        setLoader(true);
        setSimulationType(status);
        let url = 'kyc/banks/simulate';
        let payload = JSON.stringify({
            id: props.data.id,
            status,
            customer_id: location.state.user_id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                _checkUPIStatus();
            } else {
                props.handleSimulationStatus(false);
                toast.error(response.message, {
                    type: 'error'
                });
                setLoader(false);
            }
        })
    }

    // API :: check UPI status
    function _checkUPIStatus() {
        let url = 'kyc/banks/check-status';
        let payload = JSON.stringify({
            id: props.data.id,
            customer_id: location.state.user_id
        });
        APIService(true, url, payload)
        .then((response) => {
            if (response.status_code === 200 && response.data === 'success') {
                props.handleUPIVerificationSuccess(true);
                clearInterval(timer);
                setLoader(false);
            } 
            else if (response.status_code === 400) {
                toast.error(response.message, { type: 'error' });
                props.handleUPIVerificationSuccess(false);
                clearInterval(timer);
                setLoader(false);
            }
        })
        .catch((error) => {
            clearInterval(timer);
            setLoader(false);
        });
    };

    return (
        <div className={`container gx-0 ${style.e_scroll_container}`}>
            <div className="row">
                <div className="col-12 text-start">
                    {
                        props.kycFlow === false &&
                        <h4 className="color-black e-poppins-medium e-font-16 cursor-pointer mt-1"
                            onClick={() => props.navigation()}>
                            <Icon icon="back-arrow"
                                color={Colors.black}
                                size={24}
                                className={`me-3`} />
                            Link your bank account
                        </h4>
                    }
                </div>
                <div className="col-12 text-start mt-4">
                    <p className="color-outer-space e-poppins-regular e-font-14">
                        Scan the below qr code with any UPI app to complete the bank verification.
                    </p>
                </div>
                <div className="col-12 my-3">
                    <div className="d-flex justify-content-center">
                        <img src={require('../../../Assets/Images/Profile/phonePay.svg').default}
                            alt="Phone pay"
                            className="me-3" />
                        <img src={require('../../../Assets/Images/Profile/gPay.svg').default}
                            alt="Google pay"
                            className="me-3" />
                        <img src={require('../../../Assets/Images/Profile/paytm.svg').default}
                            alt="Pay TM"
                            className="me-3" />
                        <img src={require('../../../Assets/Images/Profile/bhim.svg').default}
                            alt="BHIM" />
                    </div>
                </div>
                <div className="col-12">
                    <div className={`d-flex justify-content-center ${style.e_qr_wrapper}`}>
                        {/* <img src={`data:image/png;base64,${props.data.upi_qrcode_encoded_string}`}
                        /> */}
                    </div>
                </div>
                {
                    process.env.REACT_APP_ENV !== 'PROD' && (
                        <div className="col-12 text-center mt-5">
                            <OutlineButton label="Simulate failure"
                                className={`padding-14px-tb padding-14px-lr`}
                                loading={simulationType === false && loader === true ? true : false}
                                handleClick={() => _paymentSimulation(false)} />
                            <GradientButton label="Simulate success"
                                className={`padding-14px-tb padding-36px-lr ms-3`}
                                // loading={simulationType === true && loader === true ? true : false}
                                // handleClick={() => _paymentSimulation(true)} 
                                cancel="modal"/>
                        </div>
                    )
                }

            </div>
        </div>
    )
};