/*
 *   File : nominee.js
 *   Author URI : https://evoqins.com
 *   Description :  Component which the user can add nominees to an existing KYC request.
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useRef, useState, useEffect } from "react";
import { parse, differenceInYears, format } from 'date-fns';
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap';
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { Icon } from "../../../Components/Icon";
import { GradientButton } from "../../../Components/Cta";
import { CustomSearchSelectBox, CustomTextInput, DatePicker } from "../../../Components/FormElements";

import { NomineeOTPVerification, InitiateIPV } from "../../../Components/Modal";

import APIService from "../../../Services/api-service";
import { _getKycProfile } from "../../../Helper/api";

const NOMINEE = {
    name: "",
    allocation: "",
    date_of_birth: null,
    relation: null,
    is_minor: false,
};

const NOMINEE_ERROR = {
    name: "",
    allocation: "",
    date_of_birth: "",
    relation: "",
    guardian_name: "",
    guardian_pan: "",
    guardian_relationship: "",
}

const RELATION = [
    { id: 1, label: 'Mother' },
    { id: 2, label: 'Father' }
]


const NomineePage = (props) => {
    const location = useLocation();

    const NOMINEE_DATA = useSelector(state => state.MT_ADMIN_STORE.KYC_DATA);
    const GENERAL_DATA = useSelector(state => state.MT_ADMIN_STORE.GENERAL_FILTERS);
    const nomineeErrorRef = useRef([NOMINEE_ERROR]);
    const [optInNominee, setOptInNominee] = useState(true);
    const [nomineeDetail, setNomineeDetail] = useState([NOMINEE]);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [totalAllocationError, setTotalAllocationError] = useState("");
    const [guardianOptions, setGuardianOptions] = useState([]);
    const [relationOptions, setRelationOptions] = useState(RELATION);
    const [apiLoader, setApiLoader] = useState(false);
    const [showPinModal, setShowPinModal] = useState(false);
    const [processedNomineeData, setProcessedNomineeData] = useState([]);
    const [deleteHover, setDeleteHover] = useState(-1);
    const [ipvUrl, setIpvUrl] = useState("");
    const [showIpvModal, setShowIpvModal] = useState(false);
    // max date that can be selected for dob (current date - 18 years)
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 18);

    useEffect(()=>{
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },[]);

    useEffect(() => {
        if (showIpvModal === true) {
            const modal = new Modal(document.getElementById("ipv-initiate"), {});
            modal.show();
        }
    }, [showIpvModal]);

    useEffect(() => {
        if (NOMINEE_DATA.nominees.length === 0) {
            setNomineeDetail([NOMINEE]);
            nomineeErrorRef.current = [NOMINEE_ERROR];
        }
        else {
            const nominee_data = NOMINEE_DATA.nominees.map(({ name,
                allocation,
                date_of_birth,
                relationship_id,
                relationship,
                guardian_name,
                guardian_pan,
                guardian_relationship_id,
                guardian_relationship,
                ...rest }) => {
                // Parse the date_of_birth and format it as 'dd-MM-yyyy'

                let formatted_date = date_of_birth;
                if (date_of_birth) {
                    formatted_date = format(parse(date_of_birth, 'dd-MM-yyyy', new Date()), 'dd/MM/yyyy');
                }

                return {
                    ...rest,
                    name: name,
                    allocation: allocation,
                    date_of_birth: formatted_date,
                    relation: {
                        value: relationship_id,
                        label: relationship
                    },
                    is_minor: _is18YearsOld(formatted_date),
                    guardian_name: guardian_name,
                    guardian_pan: guardian_pan,
                    guardian_relationship: {
                        value: guardian_relationship_id,
                        label: guardian_relationship
                    },
                };
            });
            for (let i = 0; i < NOMINEE_DATA.nominees.length; i++) {
                nomineeErrorRef.current.push(NOMINEE_ERROR);
            }
            setNomineeDetail(nominee_data)
        }
        if (NOMINEE_DATA.skip_nominee === true) {
            setOptInNominee(false);
        }
        else {
            setOptInNominee(true);
        }
        if (Object.keys(GENERAL_DATA).length) {
            // Get general data (to get relation and guardian options)
            let relation_data = GENERAL_DATA.nominee_relation.map((item) => {
                return ({ value: item.id, label: item.name })
            });
            let guardian_data = GENERAL_DATA.nominee_guardian_relation.map((item) => {
                return ({ value: item.id, label: item.name })
            });
            setGuardianOptions(guardian_data);
            setRelationOptions(relation_data);
        }
    }, [NOMINEE_DATA, GENERAL_DATA])

    useEffect(() => {
        if (showPinModal === true) {
            const modal = new Modal(document.getElementById("nominee-otp"), {});
            modal.show();
        }
    }, [showPinModal])

    const _handleRadioInput = () => {
        setOptInNominee(!optInNominee);
    }

    const _handleInput = (type, value, key) => {
        let nominee = [...nomineeDetail];

        switch (type) {
            case 1:
                // Create a new copy of the array
                nominee[key] = { ...nominee[key], name: value }; // Update the specific element
                nomineeErrorRef.current[key].name = "";
                setNomineeDetail(nominee);
                break;
            case 2:
                console.log("date", value)
                nominee[key] = { ...nominee[key], date_of_birth: value, is_minor: _is18YearsOld(value) }; // Update the specific element
                if (_is18YearsOld(value) === true) {
                    nominee[key] = { ...nominee[key], guardian_name: "" }
                    nominee[key] = { ...nominee[key], guardian_relationship: null }
                    nominee[key] = { ...nominee[key], guardian_pan: "" }
                    nomineeErrorRef.current[key].guardian_name = "";
                    nomineeErrorRef.current[key].guardian_relationship = "";
                    nomineeErrorRef.current[key].guardian_pan = "";
                }
                nomineeErrorRef.current[key].date_of_birth = "";
                setNomineeDetail(nominee);
                break;
            case 3:
                nominee[key] = { ...nominee[key], relation: value }; // Update the specific element
                nomineeErrorRef.current[key].relation = "";
                setNomineeDetail(nominee);
                break;
            case 4:
                nominee[key] = { ...nominee[key], allocation: value }; // Update the specific element
                nomineeErrorRef.current[key].allocation = "";
                setTotalAllocationError("");
                setNomineeDetail(nominee);
                break;
            case 5:
                nominee[key] = { ...nominee[key], guardian_name: value };
                nomineeErrorRef.current[key].guardian_name = "";
                setNomineeDetail(nominee);
                break;
            case 6:
                nominee[key] = { ...nominee[key], guardian_relationship: value };
                nomineeErrorRef.current[key].guardian_relationship = "";
                setNomineeDetail(nominee);
                break;
            case 7:
                nominee[key] = { ...nominee[key], guardian_pan: value };
                nomineeErrorRef.current[key].guardian_pan = "";
                setNomineeDetail(nominee);
                break;
        }
    }

    const _is18YearsOld = (dobString) => {
        // Parse the date of birth string into a Date object
        const dob = parse(dobString, 'dd/MM/yyyy', new Date());

        // Calculate the age difference
        const age_difference = differenceInYears(new Date(), dob);
        return age_difference >= 18 ? false : true;
    }


    const _scrollToErrorElement = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest"
        });
    }

    const _validateForm = (type) => {
        let valid = true;
        let nominee = [...nomineeDetail];
        console.log('nominee', nominee)
        nominee.forEach((item, key) => {
            console.log('key', key);
            if (item.name.length === 0) {
                nomineeErrorRef.current[key] = { ...nomineeErrorRef.current[key], name: "Please provide name" };
                _scrollToErrorElement(`name-${key}`);
                valid = false;
            }

            if (item.date_of_birth === null) {
                nomineeErrorRef.current[key] = { ...nomineeErrorRef.current[key], date_of_birth: "Please provide date of birth" };
                _scrollToErrorElement(`dob-${key}`);
                valid = false;
            }

            if (item.relation === null) {
                nomineeErrorRef.current[key] = { ...nomineeErrorRef.current[key], relation: "Please provide relation" };
                _scrollToErrorElement(`relation-${key}`);
                valid = false;
            }

            if (item.allocation.length === 0) {
                nomineeErrorRef.current[key] = { ...nomineeErrorRef.current[key], allocation: "Please provide allocation" };
                _scrollToErrorElement(`allocation-${key}`);
                valid = false;
            }
            if (item.is_minor === true) {
                if (item.guardian_name.length === 0) {
                    nomineeErrorRef.current[key] = { ...nomineeErrorRef.current[key], guardian_name: "Guardian name is required" };
                    _scrollToErrorElement(`g-name-${key}`);
                    valid = false;
                }
                if (item.guardian_relationship === null) {
                    nomineeErrorRef.current[key] = { ...nomineeErrorRef.current[key], guardian_relationship: "Guardian relation is required" };
                    _scrollToErrorElement(`g-relation-${key}`);
                    valid = false;
                }
                if (item.guardian_pan.length === 0) {
                    nomineeErrorRef.current[key] = { ...nomineeErrorRef.current[key], guardian_pan: "Guardian pan is required" };
                    _scrollToErrorElement(`g-pan-${key}`);
                    valid = false;
                } else if (!/^[A-Z]{5}[0-9]{4}[A-Z]$/.test(item.guardian_pan)) {
                    nomineeErrorRef.current[key] = { ...nomineeErrorRef.current[key], guardian_pan: "Please provide valid PAN number" };
                    _scrollToErrorElement(`g-pan-${key}`);
                    valid = false;
                }
            }
        });

        if (valid === true) {
            if (type === 1) {
                const total_allocation = nominee.reduce((acc, curr) => acc + parseInt(curr.allocation), 0);
                if (total_allocation !== 100) {
                    valid = false;
                    setTotalAllocationError("The total allocation must equal 100%");
                }
                else {
                    let proceed_nominees = _processNomineeData();
                    console.log("Processed nominee", proceed_nominees)
                    _validateNominee(proceed_nominees);
                    setProcessedNomineeData(proceed_nominees);
                    // props.updateProgress(8);
                }
            }
            else {
                _handleMore();
            }
        }
        setForceUpdate(!forceUpdate);
    }

    function _processNomineeData() {
        const updated_data = nomineeDetail.map(({ relation, guardian_name, guardian_relationship, date_of_birth, is_minor, date_of_birth_str, guardian_pan, ...rest }) => {
            console.log("Minor", is_minor)
            // Parse the date_of_birth and format it as 'dd-MM-yyyy'
            const formatted_date = format(parse(date_of_birth, 'dd/MM/yyyy', new Date()), 'dd-MM-yyyy');

            return {
                ...rest,
                relation_id: relation.value,
                guardian_name: is_minor === false ? null : guardian_name,
                guardian_pan: is_minor === false ? null : guardian_pan,
                guardian_relationship_id: is_minor === false ? null : guardian_relationship.value,
                date_of_birth: formatted_date
            };
        });
        return updated_data;
    }

    const _handleMore = () => {
        let nominee = [...nomineeDetail]
        nominee.push(NOMINEE)
        setTotalAllocationError("");
        nomineeErrorRef.current.push(NOMINEE_ERROR);
        setNomineeDetail(nominee);
    }

    function _removeNominee(key) {
        let nominee_detail = [...nomineeDetail];
        nominee_detail.splice(key, 1);
        let errorRef = [...nomineeErrorRef.current];
        setNomineeDetail(nominee_detail);
        nomineeErrorRef.current = errorRef;
    }

    function _handleCloseIpv() {
        props.updateProgress(8);
        setShowIpvModal(false);
        setIpvUrl("");
    }

    function _handleDropdownOpen(isOpen) {
        console.log(isOpen);
        if (isOpen === true) {
            let element = document.getElementById('footer-div');
            setTimeout(() => {
                element.scrollIntoView({ behavior: 'smooth' })
            }, 200);
        }
    }

    // update the UI to next step after success of add nominee
    async function _handleSuccess() {
        let initiate_ipv = _initiateIPV();
        _getKycProfile(location.state.user_id);
    }

    // API - validate nominee
    function _validateNominee(nomineeData) {
        setApiLoader(true);
        let url = 'kyc/nominee/confirm';
        let payload = JSON.stringify({
            is_nominee_available: optInNominee,
            nominee: optInNominee === true ? nomineeData : [],
            customer_id: location.state.user_id
        });

        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("OTP sent successfully", {
                    type: "success",
                });
                setShowPinModal(true);
                // _updateCountdown();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        });
    }



    // API :: add nominee
    async function _submitNominee(otp) {
        setApiLoader(true);
        let status = true;
        let url = 'kyc/add-nominee';
        let payload = JSON.stringify({
            is_nominee_available: optInNominee,
            otp,
            nominee: optInNominee === true ? processedNomineeData : [],
            customer_id: location.state.user_id
        });
        await APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                if (optInNominee === true) {
                    toast.success('Nominee details updated successfully', {
                        type: 'success'
                    });
                } else {
                    toast.success('Nominee opted out successfully', {
                        type: 'success'
                    });
                }

            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
                status = false;
            }
        });
        return status;
    }

    // API - initiateIPV 
    async function _initiateIPV() {
        let status = true;
        let url = 'kyc/ipv/initiate';
        let payload = JSON.stringify({
            customer_id: location.state.user_id
        });
        await APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setIpvUrl(response.data);
                setShowIpvModal(true);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                });
                status = false;
            }
            setApiLoader(false);
        })
        return status;
    }

    return (
        <div className="row  pb-5">
            <div className="col-lg-7 col-md-7 col-12 pe-lg-5 pe-md-5 pe-1 pb-5">
                <p className="color-black e-poppins e-poppins-bold e-font-24-sm-18 mb-2">Add nominee</p>
                <p className="color-outer-space e-poppins-regular e-font-sm-16-14 line-height-24px mb-0">
                    You can add up to 3 nominees to your account. You can add them now or later in the profile section.
                </p>
                <div className="margin-32px-top d-lg-flex d-md-flex d-block gap-40px">
                    <div className="d-flex align-items-center gap-8px cursor-pointer"
                        onClick={_handleRadioInput}>
                        <Icon icon={optInNominee === false ? "radio-unselected" : "radio-selected"}
                            size={20} />
                        <p className="color-outer-space e-poppins-regular e-font-sm-16-14 line-height-24px mb-0">I want to opt for nominee</p>
                    </div>
                    <div className="d-flex align-items-center gap-8px cursor-pointer mt-lg-0 mt-md-0 mt-3"
                        onClick={_handleRadioInput}>
                        <Icon icon={optInNominee === false ? "radio-selected" : "radio-unselected"}
                            size={20} />
                        <p className="color-outer-space e-poppins-regular e-font-sm-16-14 line-height-24px mb-0">I want to opt out for nominee</p>
                    </div>

                </div>
                {
                    optInNominee === false &&
                    <GradientButton label="Opt out & proceed"
                        className="padding-12px-tb px-4 e-font-16 margin-64px-top"
                        onPress={_validateNominee} />
                }

                {
                    optInNominee === true &&
                    <Fragment>
                        {
                            nomineeDetail.map((item, key) => {
                                return (
                                    <div className="margin-34px-top pe-lg-5 pe-md-5 pe-0 me-lg-4 me-md-4 me-0 row position-relative"
                                        key={key}>
                                        <div className="d-flex justify-content-between align-items-center mb-2">
                                            <p className="color-black e-font-sm-16-14 e-poppins-semi-bold mb-0">Nominee {key + 1}</p>
                                            {
                                                key !== 0 && (
                                                    <Icon icon={deleteHover === key ? "delete-red" : "delete"}
                                                        size={24}
                                                        className="cursor-pointer"
                                                        onMouseEnter={() => setDeleteHover(key)}
                                                        onMouseLeave={() => setDeleteHover(-1)}
                                                        onClick={() => _removeNominee(key)} />
                                                )
                                            }
                                        </div>

                                        {/*Name  */}
                                        <div className="col-12"
                                            id={`name-${key}`}>
                                            <CustomTextInput label="Name"
                                                postfix="*"
                                                personName={true}
                                                id={`name-input-${key}`}
                                                value={item.name}
                                                error={nomineeErrorRef.current[key]['name']}
                                                valueChange={(data) => _handleInput(1, data, key)} />
                                        </div>
                                        {/* Relation */}
                                        <div className="col-12"
                                            id={`relation-${key}`}>
                                            <CustomSearchSelectBox value={item.relation ? item.relation : ""}
                                                label="Relation"
                                                error={nomineeErrorRef.current[key]['relation']}
                                                postfix="*"
                                                className="margin-32px-bottom margin-32px-top"
                                                options={relationOptions}
                                                // onMenuToggle={_handleDropdownOpen}
                                                onSelectChange={(value) => _handleInput(3, value, key)} />
                                        </div>

                                        {/* DOB */}
                                        <div className="col-lg-6 col-md-6 col-12 mb-lg-0 mb-md-0 mb-2"
                                            id={`dob-${key}`}>
                                            <DatePicker label="Date of Birth"
                                                error={nomineeErrorRef.current[key]['date_of_birth']}
                                                value={item.date_of_birth}
                                                isAdult={true}
                                                maxDate={maxDate}
                                                postFix="*"
                                                dob={(value) => _handleInput(2, value, key)}
                                            // openDateModal={_handleDropdownOpen}
                                            />
                                        </div>

                                        {/* Allocation */}
                                        <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-4"
                                            id={`allocation-${key}`}>
                                            <CustomTextInput label="Allocation percentage"
                                                postfix="*"
                                                type="number"
                                                maxLength={3}
                                                id={`allocation-input-${key}`}
                                                value={item.allocation}
                                                className="margin-32px-bottom"
                                                error={nomineeErrorRef.current[key]['allocation']}
                                                valueChange={(value) => _handleInput(4, value, key)} />
                                        </div>
                                        {
                                            item.is_minor === true && (
                                                <>
                                                    {/* Guardian name */}
                                                    <div className="col-12"
                                                        id={`g-name-${key}`}>
                                                        <CustomTextInput label="Guardian name"
                                                            postfix="*"
                                                            personName={true}
                                                            id={`g-name-input-${key}`}
                                                            value={item.guardian_name}
                                                            className="margin-32px-bottom"
                                                            error={nomineeErrorRef.current[key]['guardian_name']}
                                                            valueChange={(value) => _handleInput(5, value, key)} />
                                                    </div>

                                                    {/* Guardian relation */}
                                                    <div className="col-12"
                                                        id={`g-relation-${key}`}>
                                                        <CustomSearchSelectBox value={item.guardian_relationship}
                                                            label="Guardian relation"
                                                            error={nomineeErrorRef.current[key]['guardian_relationship']}
                                                            postfix="*"
                                                            className="margin-32px-bottom "
                                                            options={guardianOptions}
                                                            onMenuToggle={_handleDropdownOpen}
                                                            onSelectChange={(value) => _handleInput(6, value, key)} />
                                                    </div>

                                                    {/* Guardian PAN */}
                                                    <div className="col-12"
                                                        id={`g-pan-${key}`}>
                                                        <CustomTextInput label="Guardian PAN number"
                                                            postfix="*"
                                                            pan={true}
                                                            id={`g-pan-input-${key}`}
                                                            maxLength={11}
                                                            value={item.guardian_pan}
                                                            inputClass="text-uppercase"
                                                            className="margin-32px-bottom"
                                                            error={nomineeErrorRef.current[key]['guardian_pan']}
                                                            valueChange={(data) => _handleInput(7, data, key)} />
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                )
                            })
                        }

                        <div className="d-lg-flex d-md-flex d-block align-items-center justify-content-between margin-40px-lg-top position-relative pe-lg-5 pe-md-5 pe-1 me-lg-4 me-md-4 me-0">
                            {
                                totalAllocationError.length !== 0 &&
                                <div className='position-absolute d-flex align-items-center e-allocation-error'>
                                    <Icon icon="info-circle"
                                        size={16}
                                        className="me-1" />
                                    <p className='color-red margin-block-end-0 e-font-12 '>
                                        {totalAllocationError}
                                    </p>
                                </div>
                            }
                            {
                                nomineeDetail.length < 3 &&
                                <div className=" align-items-center gap-8px cursor-pointer d-lg-none d-md-none d-flex mb-lg-0 mb-md-0 mb-4"
                                    onClick={() => _validateForm(2)}>
                                    <Icon icon="add-nominee"
                                        size={24} />
                                    <p className="color-primary-color e-poppins-medium e-font-sm-16-14 line-height-16px mb-0">Add new nominee </p>
                                </div>
                            }
                            <GradientButton label="Add nominee and continue"
                                className="px-4 padding-12px-tb e-font-16 mob-w-100"
                                loading={apiLoader}
                                onPress={() => _validateForm(1)} />
                            {
                                nomineeDetail.length < 3 &&
                                <div className="align-items-center gap-8px cursor-pointer d-lg-flex d-md-block d-none"
                                    onClick={() => _validateForm(2)}>
                                    <Icon icon="add-nominee"
                                        size={24} />
                                    <p className="color-primary-color e-poppins-medium e-font-sm-16-14 line-height-16px mb-0">Add new nominee </p>
                                </div>
                            }
                        </div>

                    </Fragment>

                }
                <div id="footer-div"></div>

            </div>
            {
                showPinModal === true && (
                    <NomineeOTPVerification close={() => setShowPinModal(false)}
                        handleOTPSubmission={_submitNominee}
                        handleSuccess={_handleSuccess}
                        optInStatus={optInNominee}
                        nominee={processedNomineeData} />
                )
            }
            {
                showIpvModal === true && (
                    <InitiateIPV url={ipvUrl}
                        close={_handleCloseIpv} />
                )
            }
        </div>

    )
}

export default NomineePage