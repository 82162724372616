/*
 *   File : initiate-ipv.js
 *   Author URI : https://evoqins.com
 *   Description : UI element which shows links for complete ipv
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect } from "react";
import { toast } from "react-toastify";

import { Icon } from "../Icon";

import style from "../../Styles/Components/order-execution-success.module.scss"

const InitiateIPV = (props) => {

    useEffect(() => {
        var my_modal = document.getElementById("ipv-initiate");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, [props.close]);

    // copy payment URL
    const _copyLink = () => {
        navigator.clipboard.writeText(props.url);
        toast.dismiss();
        toast.success('IPV linked copied', {
            type: 'success'
        });
    }

    // Open customer whatsapp chat
    const _openWhatsapp = () => {
        window.open('https://api.whatsapp.com/send?text=' + props.url, '_blank');
    }

    return (
        <div className={`modal fade ${style.e_order_execution} e-order-execution-sucess`}
            id="ipv-initiate"
            tabIndex="-1"
            aria-labelledby="ipv-initiate"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content  bg-white text-start d-flex align-items-center padding-32px-all ${style.e_content} `}>
                    <Icon icon="close"
                        size={24}
                        className={`cursor-pointer position-absolute ${style.e_close} e-modal-close-btn`}
                        data-bs-dismiss="modal" />
                    <img draggable={false} src={require("../../Assets/Images/Order-execution/order-execution.png")}
                        alt="Order execution"
                        width={72}
                        height={72} />

                    <p className="color-eerie-black e-poppins-medium e-font-18 mb-2 mt-3">
                        Successfully sent to user
                    </p>
                    <p className="color-outer-space e-poppins-regular e-font-14 margin-32px-bottom mx-4 text-center">
                        The IPV link have been successfully sent to the customer via their registered email. 
                        Now, you can share the URL directly with the customer using the following options.
                    </p>


                    <div className="d-flex gap-16px">
                        <div className="d-flex align-items-center gap-8px padding-12px cursor-pointer border-all  border-radius-8px e-copy-link-hover"
                            onClick={_copyLink}>
                            <Icon icon="copy"
                                size={24} />
                            <p className="color-outer-space e-poppins-regular e-font-14 mb-0">Copy link</p>
                        </div>

                        <div className="d-flex align-items-center gap-8px padding-12px cursor-pointer border-all  border-radius-8px e-copy-link-hover"
                            onClick={_openWhatsapp}>
                            <img draggable={false} src={require('../../Assets/Images/Modal/whatsapp.svg').default}
                                width={24}
                                height={24}
                                alt="whatsapp" />
                            <p className="color-outer-space e-poppins-regular e-font-14 mb-0">Share to whatsApp</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InitiateIPV