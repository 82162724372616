/*
 *   File : kyc.js
 *   Author URI : https://evoqins.com
 *   Description : Navigation screen which holds all four steps in the KYC registration flow.
 *   Integrations : null
 *   Version : v1.1
 */
// import package
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

// import components
import Icon from "../../Components/Icon/icon";
import { CustomLoader } from "../../Components/Other";
import LandingPage from "./helpers/landing";
import PANDetailsPage from "./helpers/pan-detail";
import KYCStatusPage from "./helpers/kyc-status";
import PersonalDetailsPage from "./helpers/personal-details";
import DeclarationsPage from "./helpers/declaration";
import CreateBank from "./helpers/create-bank";
import NomineePage from "./helpers/nominee";
import PreviewPage from "./helpers/preview";
import KYCStatus from "./helpers/status";
import { NavigationHeader } from "../../Components/Header";

import Store from '../../Store';

import Colors from '../../Styles/color.module.scss';
import APIService from "../../Services/api-service";

const PAGE_NAVIGATION = [
    "Dashboard",
    "Create user account"
]

export default function KYC(props) {
    const location = useLocation();
    const KYC_DATA =  useSelector(state => state.MT_ADMIN_STORE.KYC_DATA);
    const [progress, setProgress] = useState(1);
    const [backNavigation, setBackNavigation] = useState(false);
    const [kycProgress, setKycProgress] = useState({});
    const [loader, setLoader] = useState(true);

    console.log('KYC_STEP', KYC_DATA);

    useEffect(() => {
        if (location.state !== null) {
            _getKycGeneralData();
            _getGeneralData();
            _getKYCData(location.state.user_id);
        }
    }, [location]);

    function _updateProgress(value) {
        setProgress(value);
        setBackNavigation(false);
    }

    // navigate back to prev kyc step
    function _navigateBack() {
        setProgress(progress - 1);
        setBackNavigation(true);
    }

    // render page based on KYC step
    function _getKYCStep (kyc_data) {
        try {
           
            if (kyc_data.kyc_step === 0) {
                if (kyc_data.email === null || kyc_data.mobile === null) {
                    setProgress(1);
                }
                else if ( kyc_data.email !== null && kyc_data.mobile !== null) {
                    setProgress(2);
                }
                else {
                    setProgress(kyc_data.kyc_step + 1)
                }
            }
            else
                if (kyc_data.kyc_step < 7) {
                    setProgress(kyc_data.kyc_step + 2)
                }
                else {
                    setProgress(kyc_data.kyc_step + 1)
                }
            setLoader(false);
        } catch (error) {
            toast.dismiss();
            toast.error(error, {
                type: "error"
            });
        }
    }

    // API for general data
    function _getGeneralData() {
        let url = 'general/get-data';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                Store.dispatch({
                    type: 'APP_GENERAL_DATA',
                    payload: response.data
                });
            }
        })
    }

    // API - get kyc general data
    function _getKycGeneralData() {
        let url = 'kyc/general-data';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                Store.dispatch({
                    type: 'GET_KYC_FILTERS',
                    payload: response.data
                });
            }
        })
    }

    // get kyc progress data
    function _getKYCData(customer_id) {
        setLoader(true);
        let url = 'onboarding/kyc/detail';
        let payload = JSON.stringify({
            customer_id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                Store.dispatch({
                    type: 'GET_KYC_DATA',
                    payload: response.data
                });
                _getKYCStep(response.data);
            } else {
                toast.error(response.message, {
                    type: 'error'
                })
            }
        })
    }
    function KYCProgress() {
        return (
            <div className={progress > 9 ? "mt-5" : ""}>
                <p className="color-outer-space e-poppins-regular e-font-16 margin-block-end-0 mb-2">{`Step ${progress <= 10 ? progress : 10} of 10`}</p>
                <div className="d-flex mb-4">
                    {Array.from({ length: 9 }, (_, key) => (
                        <div key={key} className="bg-sliver-gray border-radius-4px position-relative e-kyc-progress-track me-1">
                            <div
                                className="bg-ocean-boat-blue border-radius-4px position-absolute e-kyc-progress-bar top-0 left-0"
                                data-completed-step={progress >= key + 1 ? true : false} // Completed step 
                                data-completing-step={progress === key + 1 && backNavigation === false ? true : false} // next step progress animation
                                data-prev-step={backNavigation && progress === key} // prev step progress animationś
                            ></div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    return (
        loader === true ?
            <CustomLoader />
            :
            <Fragment>
                <NavigationHeader type={1}
                    data={PAGE_NAVIGATION}
                    title="Create user account" />
                <div className="ps-4 pe-lg-5 pe-md-2 pe-2 padding-32px-top padding-200px-bottom">
                    {
                        progress === 1 ?
                            <div className="row">
                                <div className="col-12">
                                    <LandingPage updateProgress={_updateProgress} />
                                </div>
                            </div>
                            :
                            <div className="row">
                                <div className="col-12">
                                    {
                                        (progress > 2 && progress < 10) &&
                                        <button className="color-black e-poppins-medium e-font-sm-16-14 e-kcy-back-button px-0 bg-transparent no-border no-outline my-sm-4 my-2 py-2 "
                                            onClick={_navigateBack}>
                                            <Icon icon="back-arrow"
                                                size={24}
                                                className="me-2 e-arrow"
                                                color={Colors.black} />
                                            Back to previous step
                                        </button>
                                    }

                                    <KYCProgress />
                                    {
                                        progress === 2 ?
                                            <PANDetailsPage updateProgress={_updateProgress} />
                                            :
                                            progress === 3 ?
                                                <KYCStatusPage updateProgress={_updateProgress} />
                                                :
                                                progress === 4 ?
                                                    <PersonalDetailsPage updateProgress={_updateProgress} />
                                                    :
                                                    progress === 5 ?
                                                        <DeclarationsPage updateProgress={_updateProgress} />
                                                        :
                                                        progress === 6 ?
                                                            <CreateBank updateProgress={_updateProgress} />
                                                            :
                                                            progress === 7 ?
                                                                <NomineePage updateProgress={_updateProgress} />
                                                                :
                                                                progress === 8 ?
                                                                    <PreviewPage updateProgress={_updateProgress} />
                                                                    :
                                                                    <KYCStatus updateProgress={_updateProgress} />
                                    }
                                </div>
                            </div>
                    }

                </div>
            </Fragment>
    )
}