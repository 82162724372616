
/*
 *   File : add-admin.js
 *   Author URI : www.evoqins.com
 *   Description : Add sub admin
 *   Integrations : null
 *   Version : v1.0
 *   Created : 27-12-2023
 */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { GradientButton, OutlineButton } from "../../../Components/Cta";
import { NavigationHeader } from "../../../Components/Header";
import { SectionTitle } from "../../../Components/Title";
import { CustomSearchSelectBox, CustomTextInput, CustomPhoneInput } from "../../../Components/FormElements";
import { Icon } from "../../../Components/Icon";

import { _getAdminTypes, _getPermissions, _getZone } from "../../../Helper/api";
import APIService from "../../../Services/api-service";

const PERMISSION_OBJ = { module: '', permission: '', error: false };

export default function AddAdmin(props) {

    const navigate = useNavigate();

    let roles_options_static = [];

    const [adminTypes, setAdminTypes] = useState([]);
    const [selectedAdminType, setSelectedAdminType] = useState('');
    const [selectedAdminError, setSelectedAdminError] = useState('');
    const [adminName, setAdminName] = useState('');
    const [adminNameError, setAdminNameError] = useState('');
    const [adminEmail, setAdminEmail] = useState('');
    const [adminEmailError, setAdminEmailError] = useState('');
    const [adminPhone, setAdminPhone] = useState('');
    const [adminPhoneError, setAdminPhoneError] = useState('');
    const [adminPermissions, setAdminPermissions] = useState([PERMISSION_OBJ]);
    const [mtID, setMtID] = useState('');
    const [mtIDError, setMtIDError] = useState('');
    const [permissionOptions, setPermissionOptions] = useState([]);
    const [templatePermissions, setTemplatePermissions] = useState();

    const [zoneOptions, setZoneOptions] = useState([]);
    const [selectedZone, setSelectedZone] = useState({});
    const [selectedZoneError, setSelectedZoneError] = useState('');

    const [regionOptions, setRegionOptions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState({});
    const [selectedRegionError, setSelectedRegionError] = useState('');

    const [branchOptions, setBranchOptions] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState({});
    const [selectedBranchError, setSelectedBranchError] = useState('');

    const [managerOptions, setManagerOptions] = useState([]);
    const [selectedManager, setSelectedManager] = useState({});
    const [selectedManagerError, setSelectedManagerError] = useState('');


    const [apiLoader, setApiLoader] = useState(false);

    useEffect(() => {
        _fetchAdminList();
        _fetchPermissions();
    }, []);

    useEffect(() => {
        setApiLoader(true);
        if (selectedAdminType.length !== 0 && selectedAdminType.id > 3 && selectedAdminType.id <= 7) {
            _getZoneOptions();
        }
        _clearData();
    }, [selectedAdminType]);

    useEffect(() => {
        if (Object.keys(selectedZone).length !== 0 && selectedAdminType.id > 4 && selectedAdminType.id <= 7) {
            _getRegion();
        }
    }, [selectedZone]);

    useEffect(() => {
        if (Object.keys(selectedRegion).length !== 0 && selectedAdminType.id > 5 && selectedAdminType.id <= 7) {
            _getBranch();
        }
    }, [selectedRegion]);

    useEffect(() => {
        if (Object.keys(selectedBranch).length !== 0 && selectedAdminType.id > 6 && selectedAdminType.id <= 7) {
            _getManager();
        }
    }, [selectedBranch]);

    const _clearData = () => {
        setRegionOptions([]);
        setBranchOptions([]);
        setManagerOptions([]);
        setSelectedZone({});
        setSelectedRegion({});
        setSelectedBranch({});
        setSelectedManager({});
        setSelectedZoneError('');
        setSelectedRegionError('');
        setSelectedBranchError('');
        setSelectedManagerError('');
        setApiLoader(false);
    }

    const _selectZone = (zone) => {
        setSelectedZone(zone);
        setSelectedRegion({});
        setSelectedBranch({});
        setSelectedManager({});
        setSelectedZoneError('');
    }

    const _selectRegion = (region) => {
        setSelectedRegion(region);
        setSelectedBranch({});
        setSelectedManager({});
        setSelectedRegionError('');
    }

    const _selectBranch = (branch) => {
        setSelectedBranch(branch);
        setSelectedManager({});
        setSelectedBranchError('');
    }

    const _selectManager = (manager) => {
        setSelectedManager(manager);
        setSelectedManagerError('');
    }

    // update admin type 
    function _handleAdminTypeChange(value) {
        setSelectedAdminType(value);
        setSelectedAdminError('');
        _getPermissionTemplate(value.id);
    }
    // update admin name input
    function _handleAdminName(value) {
        setAdminName(value);
        setAdminNameError('');
    }

    // get permission template based on selected admin type
    function _getPermissionTemplate(id) {
        let template = templatePermissions[id];
        let selected_permissions = [];
        let permission_array = permissionOptions;
        if (template !== undefined) {
            selected_permissions = template.map((item, key) => {
                let permission_options = permission_array.filter(value => item.module_id === value.value);
                // hiding selected module from module option list
                let index = permission_array.findIndex(value => item.module_id === value.value); 
                if (index > -1) {
                    permission_array[index].show_option = false;
                }
                return (
                    {
                        module: {
                            value: item.module_id,
                            label: item.module,
                            permissions: permission_options[0]?.permissions === undefined ? [] : permission_options[0]?.permissions
                        },
                        permission: { value: item.permission_type_id, label: item.action },
                        error: false
                    }
                )
            });
            setPermissionOptions(permission_array);
            setAdminPermissions(selected_permissions);
        }else{
            setAdminPermissions([PERMISSION_OBJ]);
        }
    }

    // update MT ID
    function _handleMtId(value) {
        setMtID(value);
        setMtIDError('');
    }

    // update admin mail input
    function _handleAdminMail(value) {
        setAdminEmail(value);
        setAdminEmailError('');
    }

    // update admin number input 
    function _handleAdminPhone(value) {
        setAdminPhone(value);
        setAdminPhoneError('');
    }

    // navigate to listing
    function _navigateToListing() {
        navigate('/manage-sub-admin');
    }
    // handle role option change
    function _handleRoleChange(value, index) {
        let roles = [...adminPermissions];
        roles[index] = {
            module: value,
            permission: ''
        };
        let updated_roles_options = [...permissionOptions];
        // removing selected item from role menu option by setting show_option as false, 
        // in component we handle this based on show_option 
        let option_index = updated_roles_options.findIndex(item => item.value === value.value);
        if (option_index !== -1) {
            updated_roles_options[option_index].show_option = false;
        }
        setPermissionOptions(updated_roles_options);
        setAdminPermissions(roles);
    }

    // handle action option change
    function _handleActionChange(value, key) {
        let actions = [...adminPermissions];
        actions[key] = {
            module: actions[key].module,
            permission: value
        };
        setAdminPermissions(actions);
    }

    // add new permission to array
    function _handleAddNew() {
        const is_valid = adminPermissions.every(element => element.module !== '' && element.permission !== '');
        // validating before adding new items;
        if (is_valid) {
            setAdminPermissions([...adminPermissions, { module: '', permission: '', error: false }]);
        } else {
            const updated_permissions = adminPermissions.map(element => ({
                ...element,
                error: element.module === '' || element.permission === '',
            }));
            setAdminPermissions(updated_permissions);
        }
    }

    // Remove permission from array
    function _deletePermission(index) {
        let permission_array = [...adminPermissions];
        let updated_roles_options = [...permissionOptions];
        let dropdown_option = permission_array[index].module;
        let option_index = updated_roles_options.findIndex(item => item.value === dropdown_option.value);
        if (option_index !== -1) {
            updated_roles_options[option_index].show_option = true;
        }
        setPermissionOptions(updated_roles_options);
        permission_array.splice(index, 1);
        setAdminPermissions(permission_array);
    }

    // Scroll to form element  
    function _scrollToElement(id) {
        let element = document.getElementById(id);
        element.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest"
        });
    }

    // validate form before submitting 
    function _validateForm() {
        let valid = true;
        const is_valid_permissions = adminPermissions.every(element => element.module !== '' && element.permission !== '');
        if (selectedAdminType === '') {
            setSelectedAdminError('Admin type is required');
            _scrollToElement('admin-type-input');
            valid = false;
        }
        if (adminName.trim().length === 0) {
            setAdminNameError('Admin name is required');
            _scrollToElement('admin-name-input');
            valid = false
        }
        if (mtID.trim().length === 0) {
            setMtIDError('Create code is required');
            _scrollToElement('admin-code-input');
            valid = false
        }
        if (adminEmail.trim().length === 0) {
            setAdminEmailError('Admin Email is required');
            _scrollToElement('admin-email-input');
            valid = false;
            //eslint-disable-next-line
        } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(adminEmail)) {
            setAdminEmailError('Invalid email');
            _scrollToElement('admin-email-input');
            valid = false;
        }
        if (adminPhone === '') {
            setAdminPhoneError('Admin phone is required');
            _scrollToElement('admin-number-input');
            valid = false;
        } else if (adminPhone.length !== 10) {
            setAdminPhoneError('Invalid phone');
            _scrollToElement('admin-number-input');
            valid = false;
        }
        if (is_valid_permissions === false) {
            const updated_permissions = adminPermissions.map(element => ({
                ...element,
                error: element.module === '' || element.permission === '',
            }));
            setAdminPermissions(updated_permissions);
            updated_permissions.forEach((element, key) => {
                if (element.module === '') {
                    _scrollToElement(`admin-role-input-${key}`);
                }
                if (element.role === '') {
                    _scrollToElement(`admin-action-input-${key}`);
                }
            });
            valid = false;
        }
        // checking for duplicate
        else {
            const module_labels = adminPermissions.map(entry => entry.module.label);
            const has_duplicates = new Set(module_labels).size !== module_labels.length;
            if (has_duplicates) {
                toast.dismiss();
                toast.error('Please avoid duplicate Roles', {
                    type: 'error'
                });
                valid = false;
            }
        }

        if (selectedAdminType.id > 3 && selectedAdminType.id <= 7) {
            if (Object.keys(selectedZone).length === 0) {
                setSelectedZoneError("Select zone");
                _scrollToElement('admin-zone');
                valid = false;
            }
        }
        if (selectedAdminType.id > 4 && selectedAdminType.id <= 7) {
            if (Object.keys(selectedRegion).length === 0) {
                setSelectedRegionError("Select region")
                _scrollToElement('admin-region-input');
                valid = false;
            }
        }
        if (selectedAdminType.id > 5 && selectedAdminType.id <= 7) {
            if (Object.keys(selectedBranch).length === 0) {
                setSelectedBranchError("Select branch");
                _scrollToElement('admin-branch-input');
                valid = false;
            }
        }
        if (selectedAdminType.id > 6 && selectedAdminType.id <= 7) {
            if (Object.keys(selectedManager).length === 0) {
                setSelectedManagerError("Select manager");
                _scrollToElement('admin-manager-input');
                valid = false;
            }
        }

        if (valid === true) {
            _createNewAdmin();
        }
    }

    // API - get admin type list
    async function _fetchAdminList() {
        let admin_data = await _getAdminTypes();
        if (admin_data.length !== 0) {
            admin_data.forEach(item => {
                item.label = item.name;
                delete item.name;
            });
            setAdminTypes(admin_data);
        } else {
            setAdminTypes([]);
        }
    };

    // API - get permissions 
    async function _fetchPermissions() {
        let permission_data = await _getPermissions();
        setTemplatePermissions(permission_data.permission_templates);
        if (permission_data.modules.length !== 0) {
            let formatted_data = permission_data.modules.map(item => ({
                value: item.id,
                label: item.name,
                permissions: item.permissions.map(permission => ({
                    value: permission.permission_id,
                    label: permission.name
                })),
                short_name: item.short_name,
                show_option: true,
            }));
            setPermissionOptions(formatted_data);
            roles_options_static = formatted_data;
        } else {
            setPermissionOptions([]);
        }

    }

    // API - add admin 
    function _createNewAdmin() {
        setApiLoader(true);
        const permissions = adminPermissions.map((item) => {
            return { module_id: item.module.value, permission_type_id: item.permission.value }
        });
        console.log('selectedAdminType', selectedAdminType);
        let mapping_id = null;
        if (selectedAdminType.id === 4) {
            mapping_id = selectedZone.value;
            console.log("inside here", mapping_id)
        }
        else if (selectedAdminType.id === 5) {
            mapping_id = selectedRegion.value;
        }
        else if (selectedAdminType.id === 6) {
            mapping_id = selectedBranch.value;
        }
        else if (selectedAdminType.id === 7) {
            mapping_id = selectedManager.value;
        }
        let url = 'auth/add-admin';
        let payload = JSON.stringify({
            name: adminName,
            email: adminEmail,
            admin_type_id: selectedAdminType.id,
            mt_id: mtID,
            phone: adminPhone,
            permissions,
            dion_mapping_id: mapping_id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status === true) {
                toast.success('Admin added successfully', {
                    type: 'success'
                });
                _navigateToListing();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setApiLoader(false);
        });
    }

    // API- helper function to fetch and set data for region, branch and manager
    const _fetchAndSetOptions = async (url, request_data, setOptionsCallback) => {
        try {
            const response = await APIService(true, url, request_data);

            if (response.status_code === 200) {
                const mappedData = response.data.map((item) => ({
                    value: item.id,
                    label: item.name
                }));
                setOptionsCallback(mappedData);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        } catch (error) {
            toast.dismiss();
            toast.error(error, {
                type: "error",
            });
        }
    };

    // API - get zone
    async function _getZoneOptions() {
        let data = await _getZone();
        const modified_data = data.map((item) => {
            return {
                value: item.id,
                label: item.name
            }
        })
        setZoneOptions(modified_data);
    }

    // API - get region
    const _getRegion = () => {
        const url = "client_mapping/region/list";
        const request_data = { "id": selectedZone.value };
        _fetchAndSetOptions(url, request_data, setRegionOptions);
    };

    // API - get branch
    const _getBranch = () => {
        const url = "client_mapping/branch/list";
        const request_data = { "id": selectedRegion.value };
        _fetchAndSetOptions(url, request_data, setBranchOptions);
    };

    // API - get manager
    const _getManager = () => {
        const url = "client_mapping/rm/list";
        const request_data = { "id": selectedBranch.value };
        _fetchAndSetOptions(url, request_data, setManagerOptions);
    };

    useEffect(() => {
        console.log("selectedAdminType", selectedAdminType);
    }, [selectedAdminType])

    return (
        <>
            {/* header */}
            <NavigationHeader type={1}
                title="Role categorization" />

            <div className="ps-4 pe-lg-5 pe-md-2 pe-2 ">
                <div className="row padding-32px-bottom">
                    <div className="col-12 d-flex justify-content-between">
                        <SectionTitle title="Create admin" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-12">
                                <CustomSearchSelectBox label="Additional information"
                                    value={selectedAdminType}
                                    placeholder=""
                                    id="admin-type-input"
                                    error={selectedAdminError}
                                    options={adminTypes}
                                    postfix="*"
                                    onSelectChange={_handleAdminTypeChange} />
                            </div>
                            <div className="col-12 mt-4 pt-3">
                                <CustomTextInput label="Add sub admin name"
                                    value={adminName}
                                    postfix="*"
                                    id="admin-name-input"
                                    type="text"
                                    error={adminNameError}
                                    valueChange={_handleAdminName} />
                            </div>
                            <div className="col-12 mt-4 pt-3">
                                <CustomTextInput label="Email"
                                    value={adminEmail}
                                    postfix="*"
                                    id="admin-email-input"
                                    type="text"
                                    error={adminEmailError}
                                    valueChange={_handleAdminMail} />
                            </div>
                            <div className="col-12 mt-4 pt-3">
                                <CustomPhoneInput label="Phone"
                                    value={adminPhone}
                                    postfix="*"
                                    id="admin-number-input"
                                    type="number"
                                    error={adminPhoneError}
                                    handleChange={_handleAdminPhone} />
                            </div>
                            <div className="col-12 mt-4 pt-3">
                                <CustomTextInput label="Create code"
                                    value={mtID}
                                    postfix="*"
                                    id="admin-code-input"
                                    type="text"
                                    error={mtIDError}
                                    valueChange={_handleMtId} />
                            </div>

                            {
                                selectedAdminType.length !== 0 && selectedAdminType.id > 3 && selectedAdminType.id <= 7 &&
                                <div className="col-12 mt-4 pt-3">
                                    <CustomSearchSelectBox options={zoneOptions}
                                        value={Object.keys(selectedZone).length === 0 ? "" : selectedZone}
                                        label="Select zone"
                                        error={selectedZoneError}
                                        id="admin-zone"
                                        hide_error_text={false}
                                        placeholder=""
                                        postfix="*"
                                        onSelectChange={_selectZone} />
                                </div>
                            }

                            {
                                selectedAdminType.length !== 0 && selectedAdminType.id > 4 && selectedAdminType.id <= 7 &&

                                <div className="col-12 mt-4 pt-3">
                                    <CustomSearchSelectBox options={regionOptions}
                                        value={Object.keys(selectedRegion).length === 0 ? "" : selectedRegion}
                                        label="Select Region"
                                        error={selectedRegionError}
                                        hide_error_text={false}
                                        id="admin-region-input"
                                        placeholder=""
                                        postfix="*"
                                        onSelectChange={_selectRegion} />
                                </div>
                            }
                            {
                                selectedAdminType.length !== 0 && selectedAdminType.id > 5 && selectedAdminType.id <= 7 &&
                                <div className="col-12 mt-4 pt-3">
                                    <CustomSearchSelectBox options={branchOptions}
                                        value={Object.keys(selectedBranch).length === 0 ? "" : selectedBranch}
                                        label="Select Branch"
                                        error={selectedBranchError}
                                        id="admin-branch-input"
                                        hide_error_text={false}
                                        placeholder=""
                                        postfix="*"
                                        onSelectChange={_selectBranch} />
                                </div>
                            }
                            {
                                selectedAdminType.length !== 0 && selectedAdminType.id > 6 && selectedAdminType.id <= 7 &&
                                <div className="col-12 mt-4 pt-3">
                                    <CustomSearchSelectBox options={managerOptions}
                                        value={Object.keys(selectedManager).length === 0 ? "" : selectedManager}
                                        label="Select manager"
                                        error={selectedManagerError}
                                        id="admin-manager-input"
                                        hide_error_text={false}
                                        placeholder=""
                                        postfix="*"
                                        onSelectChange={_selectManager} />
                                </div>
                            }



                            <div className="col-12 mt-4 pt-2">
                                <SectionTitle title="Add admin permissions" />
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        {
                            adminPermissions.map((item, key) => {
                                return (
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="row"  key={key}>
                                                <div className={`col-6 ${key === 0 ? 'mt-3' : 'mt-4'} `}>
                                                    <CustomSearchSelectBox label="Role"
                                                        value={item.module}
                                                        placeholder=""
                                                        isDisabled={selectedAdminType.length !== 0 ? false : true}
                                                        error={item.error === true && item.module === '' ? 'Role required' : ''}
                                                        id={`admin-role-input-${key}`}
                                                        options={permissionOptions}
                                                        postfix="*"
                                                        onSelectChange={(value) => _handleRoleChange(value, key)} />

                                                </div>
                                                <div className={`col-6 ${key === 0 ? 'mt-3' : 'mt-4'}`}
                                                    data-disabled={item.module === '' ? 'true' : 'false'}>
                                                    <CustomSearchSelectBox label="Action"
                                                        value={item.permission}
                                                        placeholder=""
                                                        error={item.error === true && item.permission === '' ? 'Action required' : ''}
                                                        options={item.module.permissions}
                                                        id={`admin-action-input-${key}`}
                                                        postfix="*"
                                                        onSelectChange={(value) => _handleActionChange(value, key)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 mt-4 pt-2">
                                            <span className="color-primary-color e-font-16 line-height-16px e-poppins-medium cursor-pointer me-3" onClick={() => _deletePermission(key)}>
                                                <Icon icon="delete" size={24} className='me-2' />
                                                Delete
                                            </span>
                                            {
                                                key + 1 === adminPermissions.length && permissionOptions.length !== adminPermissions.length && (
                                                    <span className="color-primary-color e-font-16 line-height-16px e-poppins-medium cursor-pointer " onClick={_handleAddNew}>
                                                        <Icon icon="add-circle1" size={24} className='me-2' />
                                                        Add new
                                                    </span>
                                                )
                                            }

                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        adminPermissions.length === 0 && adminPermissions.length < permissionOptions.length && (
                            <div className="col-12 h-initial position-relative mt-3">
                                <span className="color-primary-color e-font-16 line-height-16px e-poppins-medium cursor-pointer " onClick={_handleAddNew}>
                                    <Icon icon="add-circle1" size={24} className='me-2' />
                                    Add new
                                </span>
                            </div>
                        )
                    }

                    <div className="col-12 mt-4 pt-3">
                        <OutlineButton label="Cancel"
                            onPress={_navigateToListing}
                            className="me-3" />
                        <GradientButton label="Save & send invite"
                            className="padding-12px-lr"
                            loading={apiLoader}
                            onPress={_validateForm} />
                    </div>
                </div>
            </div>
        </>
    );
}
