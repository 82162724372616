/*
 *   File : login-logs.js
 *   Author URI : https://evoqins.com
 *   Description : Page to list login and logout which the sub-admin did in MT Wealth 
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useRef, useState } from "react"
import { format } from "date-fns";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Modal } from "bootstrap";

import { NavigationHeader } from "../../../Components/Header"
import { CustomLoader, EmptyScreen } from "../../../Components/Other";
import { CustomDateRangePicker, DataTableContainer, SearchInput, CustomSearchSelectBox } from "../../../Components/FormElements";
import { ExploreFilterPopup } from "../../../Components/Popups";
import { Icon } from "../../../Components/Icon";
import { DownloadButton } from "../../../Components/Cta";
import { useClickOutside } from "../../../Helper/helper-function";
import { UnlockSIPModal } from "../../../Components/Modal";
import APIService from "../../../Services/api-service";

import Colors from "../../../Styles/color.module.scss";

const LOG_TYPE_FILTER = [
    {
        value: null,
        label: 'All'
    },
    {
        value: 1,
        label: 'Deleted users'
    }
]

const TABLE_STYLE = {
    table: {
        style: {
            padding: "12px 16px 16px 16px",
            background: Colors.white,
        },
    },
    tableBody: {
        style: {
            margin: '0px',
            marginTop: "0px !important"
        }
    },
    headRow: {
        style: {
            background: 'transparent',
            borderRadius: "16px 16px 0px 0px",
            border: "0",
            borderBottom: `1px solid ${Colors.bright_gray} !important`,
            fontSize: "14px",
            fontFamily: "Poppins-Medium",
            lineHeight: "24px",
            color: Colors.outer_space
        }
    },
    rows: {
        style: {
            margin: "0",
            background: "transparent",
            border: `1px solid ${Colors.bright_gray} !important`,
            borderRadius: "16px",
            marginTop: "14px",
            fontSize: "16px",
            fontFamily: "Poppins-Regular",
            lineHeight: "24px",
        },
    },
    cells: {
        style: {
            '&:not(:last-child)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                display: 'flex',
            },
            '&:first-child': {
                padding: "24px 0px 24px 24px",
            }
        },
    },
    headCells: {
        style: {
            '&:not(:last-child)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                display: 'flex',
            },
            '&:first-child': {
                padding: "24px 0px 24px 24px",
            }
        },
    },
    pagination: {
        style: {
            borderTop: `none `,
            color: Colors.outer_space,
            fontSize: '16px',
            fontFamily: "Poppins-Regular",
        },
    }
}

const LoginLogs = () => {

    const navigate = useNavigate();
    const popupRef = useRef(null);
    const filterRef = useRef(null);
    const [selectedRange, setSelectedRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });
    const [startDate, setStartDate] = useState("Start date");
    const [endDate, setEndDate] = useState("End date");
    const [showDateFilter, setShowDateFilter] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [showFilter, setShowFilter] = useState(false);
    const [filterData, setFilterData] = useState({});
    const [role, setRole] = useState([])
    const [loginData, setLoginData] = useState([])
    const [loader, setLoader] = useState(true);
    const [apiLoader, setApiLoader] = useState(true);
    const [initialLoader, setInitialLoader] = useState(true);
    const [filterCount, setFilterCount] = useState(0);
    const [selectedLogType, setSelectedLogType] = useState({});
    const [paginationData, setPaginationData] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [pageSize, setPageSize] = useState(20);
    const [totalLength, setTotalLength] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState(new Set());
    const [rowSelectFalse, setRowSelectFalse] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [downloadLoader, setDownloadLoader] = useState(false);
    const [unlockModal, setUnlockModal] = useState(false);

    useEffect(() => {
        _getFilterData();
    }, []);

    // for deselecting header checkbox in the table
    useEffect(() => {
        if (rowSelectFalse === true) {
            setSelectAll(false);
        }
    }, [rowSelectFalse]);

    useEffect(() => {
        if (unlockModal === true) {
            const modal = new Modal(document.getElementById("unlock"));
            modal.show();
        }
    }, [unlockModal]);

    useEffect(() => {
        if (selectAll === true) {
            const customer_id_set = new Set(loginData.map((item) => item.id));
            setSelectedUsers((prevSet) => new Set([...prevSet, ...customer_id_set]));
            setRowSelectFalse(false);
        }
        else {
            if (rowSelectFalse === false) {
                setSelectedUsers(new Set());
            }
        }
    }, [selectAll, loginData]);

    useEffect(() => {
        if (initialLoader === false) {
            _getUserList();
        }
    }, [role, startDate, endDate, selectedLogType, pageNumber])

    useEffect(() => {
        if (showFilter === true) {
            document.body.style.overflow = 'hidden';
            window.scrollTo({
                behavior: "smooth",
                top: 20
            });

        } else {
            document.body.removeAttribute("style");
        }
    }, [showFilter]);

    useEffect(() => {
        if (role.length !== 0 && !role.includes(null)) {
            setFilterCount(role.length);
        } else {
            setFilterCount(0);
        }

    }, [role])

    useEffect(() => {
        if (showFilter === true) {
            setShowDateFilter(false);
        }
        else {
            setShowFilter(false);
        }
    }, [showDateFilter, showFilter]);

    useClickOutside(filterRef, () => {
        setShowFilter(false);
    });

    const USER_COLUMNS = [

        {
            name: <div className={`position-relative w-fit-content padding-40px-left`}>
                <Icon icon={selectAll === true ? "checked" : "unchecked"}
                    size={22}
                    className="position-absolute left-10 cursor-pointer"
                    onClick={(event) => _handleSelectAll(event)} />

                <p className="mb-0">Sub-admin ID</p>
            </div>,
            selector: row => row['admin_id'],
            sortable: false,
            left: true,
            // width: "576px",
            width: "180px",
            // cell: row => <p className="color-outer-space e-poppins-regular e-font-16 mb-0">{row['admin_id']}</p>
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name ps-2">
                    <Icon icon={selectedUsers.has(row['id']) ? "checked" : "unchecked"}
                        size={22}
                        className="cursor-pointer"
                        onClick={(event) => _handleRowCheckBox(row, event)} />
                    <p className="color-outer-space e-poppins-regular e-font-16 line-height-24px mb-0 bg-white mt-0">{row['admin_id']}</p>
                </div>
        },
        {
            name: 'Sub-admin name',
            selector: row => row['admin_name'],
            sortable: false,
            left: true,
            width: "180px",
            cell: row => <p className="color-outer-space e-poppins-regular e-font-16 mb-0">{row['admin_name']}</p>
        },

        {
            name: 'Admin user type',
            selector: row => row['admin_type'],
            sortable: false,
            left: true,
            width: "180px",
            cell: row => <p className="color-black e-poppins-regular e-font-16 mb-0">{row['admin_type']}</p>
        },
        {
            name: 'Customer ID',
            selector: row => row['customer_id'],
            sortable: false,
            left: true,
            width: "180px",
            cell: row => <p className={`${row['customer_id'] !== null ? 'color-primary-color cursor-pointer' : 'color-outer-space'} e-poppins-regular e-font-16 mb-0`}
                onClick={() => _navigateToDetail(row['customer_id'])}>{row['customer_id'] ? row['customer_id'] : '-'}</p>
        },
        {
            name: 'Activity',
            selector: row => row['activity'],
            sortable: false,
            left: true,
            cell: row => <p className="color-outer-space e-poppins-regular e-font-16 mb-0">{row['activity']}</p>
        },

    ];

    const _handleShowDateFilter = () => {
        setShowDateFilter(!showDateFilter);
    }

    const _handleDateRangeChange = (newRange) => {
        setShowDateFilter(false);
        setStartDate(format(newRange.startDate, "dd-MM-yyyy"));
        setEndDate(format(newRange.endDate, "dd-MM-yyyy"));
        setSelectedRange(newRange);
        setPageNumber(1);
    };

    const _handleRowCheckBox = (row, event) => {
        // console.log("Row", row);
        event.stopPropagation();
        // console.log("before", selectedUsers)
        if (selectedUsers.has(row.id)) {
            setSelectedUsers((prevCustomerIds) => {
                const new_customer_ids = new Set(prevCustomerIds);
                new_customer_ids.delete(row.id);
                return new_customer_ids;
            });
        }
        else {
            setSelectedUsers((prevCustomerIds) => new Set([...prevCustomerIds, row.id]));
        }
        setRowSelectFalse(true);
    }

    const _handleClearDates = () => {
        setShowDateFilter(false);
        setSelectedRange({
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        });
        setStartDate("Start date");
        setEndDate("End date");
    }

    const _handleSearchQuery = (value) => {
        setSearchQuery(value)
    }

    const _handleToggleFilter = () => {
        setShowFilter(!showFilter);
    }

    const _handleApplyFilter = (role) => {
        setShowFilter(false);
        setRole(role);
        setPageNumber(1);
    }

    const _handleSelectAll = (event) => {
        event.stopPropagation();
        setSelectAll(!selectAll)
    }

    const _navigateToDetail = (id) => {
        if (id !== null) {
            navigate("/user-summary/customer-detail", {
                state: {
                    user_id: id
                }
            });
        }
    }

    const _handleCardClick = (row) => {

    }

    function _handleTypeFilter(value) {
        setPageNumber(1);
        setSelectedLogType(value);
    }

    // handler for page change
    function _nextPage() {
        setPageNumber(prevCount => prevCount + 1);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    // got to previous page
    function _previousPage() {
        setPageNumber(prevCount => prevCount - 1);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    // API - search data
    function _getUserList() {
        setApiLoader(true);
        let url = 'login-logs/get';
        let payload = JSON.stringify({
            admin_id: searchQuery.length === 0 ? null : searchQuery,
            filter_ids: role[0] === null ? null : role,
            start_date: startDate === "Start date" ? null : startDate,
            end_date: endDate === "End date" ? null : endDate,
            logs_type: selectedLogType.value,
            page_num: pageNumber,
            page_size: pageSize
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                let total_pages = Math.ceil(response.data.number_of_records / response.data.page_size);
                setTotalPages(total_pages);
                setPaginationData({
                    rowsPerPageText: `${response.data.page} | Total records: ${response.data.number_of_records}`,
                });
                setTotalLength(response.data.number_of_records);
                setLoginData(response.data.data);
            }
            else {
                setLoginData([]);
            }
            setApiLoader(false);
        })
    }

    // API - Get filter data 
    function _getFilterData() {
        let url = 'auth/list-admin-types';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                const roles_with_id_label = [
                    { id: null, label: 'All' },
                    ...response.data.map(role => ({
                        id: role.id,
                        label: role.name
                    }))
                ];
                setRole([null]);
                setInitialLoader(false);

                console.log("ROles", roles_with_id_label)
                setFilterData(roles_with_id_label);
            }
            setLoader(false)
        })
    }

    // API confirm download
    const _confirmDownload = () => {
        setDownloadLoader(true);
        const customer_id = [...selectedUsers]
        const url = "login-logs/confirm-download";

        const data = {
            "log_ids": selectAll === true ? [] : customer_id
        }

        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setDownloadLoader(false);
                setUnlockModal(true);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        })
    }

    // download logs
    const _downloadLogs = (pin) => {
        setLoader(true);
        const customer_id = [...selectedUsers]
        const url = "login-logs/download";

        const data = {
            "log_ids": selectAll === true ? [] : customer_id,
            "otp": pin,
            "type": "DOWNLOAD"
        }

        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                window.open(response.data.url, "_blank");
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
            setLoader(false);
            setUnlockModal(false);
            setSelectedUsers(new Set());
            setSelectAll(false);
        })
    }

    return (
        <Fragment>
            <NavigationHeader type={1}
                title="Admin logs" />
            {
                apiLoader === true ?
                    <div className="h-80vh">
                        <CustomLoader />
                    </div>
                    :
                    <div className="ps-4 pe-lg-5 pe-md-2 pe-2 padding-32px-top min-height-100vh">
                        <div className="row">
                            <div className="offset-4 col-8 d-flex gap-16px justify-content-end">
                                <div className="col-5">
                                    <CustomSearchSelectBox options={LOG_TYPE_FILTER}
                                        value={Object.keys(selectedLogType).length !== 0 ? selectedLogType : ""}
                                        // label="Select user type"
                                        id="investment-type-dropdown"
                                        hide_error_text={false}
                                        placeholder="Select log type"
                                        onSelectChange={_handleTypeFilter} />
                                </div>
                                <div className="position-relative">
                                    <CustomDateRangePicker
                                        startDate={startDate}
                                        endDate={endDate}
                                        popupRef={popupRef}
                                        showDateFilter={showDateFilter}
                                        handleShowDateFilter={_handleShowDateFilter}
                                        selectedRange={selectedRange}
                                        handleDateRangeChange={_handleDateRangeChange}
                                        clearDates={_handleClearDates}
                                        className="h-100" />
                                </div>

                                <DownloadButton onPress={_confirmDownload}
                                    // disabled={selectedSips.length !== 0 ? false : true}
                                    loader={downloadLoader}
                                    className="ms-2" />

                                <div className="position-relative" >
                                    {
                                        filterCount > 0 &&
                                        <span className="color-white border-radius-100px e-poppins-bold e-font-12 line-height-12px e-filter-badge position-absolute bg-sea-green d-flex justify-content-center align-items-center">{filterCount}</span>
                                    }
                                    <div className="border-radius-6px p-2 border-bright-gray border-all bg-white  d-flex align-items-center z-index-10 h-100"
                                        onClick={_handleToggleFilter}>
                                        <p className="color-charleston-green e-poppins-medium e-font-14 line-height-24px mb-0 pe-1 cursor-pointer mt-0">Filter</p>
                                        <img draggable={false} src={require('../../../Assets/Images/Users/filter.png')}
                                            alt="Filter"
                                            width={24}
                                            height={24}
                                            className="cursor-pointer me-2" />
                                    </div>
                                    {
                                        showFilter === true &&
                                        <Fragment>
                                            <div className="position-relative" ref={filterRef}>
                                                <ExploreFilterPopup type={2}
                                                    data={filterData}
                                                    role={role}
                                                    applyFilter={_handleApplyFilter}
                                                    className="h-100"
                                                    close={() => setShowFilter(false)} />
                                            </div>
                                            <div className="position-fixed w-100 z-index-8 h-100 left-0 top-20px cursor-default"
                                                onClick={() => setShowFilter(false)}>
                                            </div>
                                        </Fragment>
                                    }
                                </div>
                            </div>

                            {
                                loginData.length !== 0 ?
                                    <div className="col-12 mt-3 px-0 position-relative">
                                        <DataTableContainer columns={USER_COLUMNS}
                                            data={loginData}
                                            selectableRows={false}
                                            pagination={true}
                                            customStyles={TABLE_STYLE}
                                            paginationTotalRows={totalLength}
                                            paginationComponentOptions={paginationData}
                                            onChangeRowsPerPage={(e) => {
                                                setPageSize(e);
                                                setPageNumber(1);
                                            }}
                                            paginationPerPage={pageSize}
                                            rowClick={(row) => {
                                                _handleCardClick(row)
                                            }} />
                                        <div className={`d-flex justify-content-end align-items-center mt-3 position-absolute bottom-16px right-24 w-max-content`}>
                                            <Icon icon="pagination-previous" width="24px" height="24px"
                                                className={pageNumber === 1 ? 'pointer-events-none opacity-4 mx-3' : 'mx-3 cursor-pointer'}
                                                onClick={_previousPage} />
                                            <Icon icon="pagination-next" width="24px" height="24px"
                                                className={pageNumber === totalPages ? 'ms-1 pointer-events-none opacity-4' : 'ms-1 cursor-pointer'}
                                                onClick={_nextPage} />
                                        </div>
                                    </div>
                                    :
                                    <EmptyScreen title="Empty login data!" />
                            }

                        </div>
                    </div>
            }
            {
                unlockModal === true ?
                    <UnlockSIPModal
                        handleOTPSubmission={_downloadLogs}
                        close={() => {
                            setUnlockModal(false);
                        }} />
                    :
                    null
            }

        </Fragment>
    )
}

export default LoginLogs