/*
 *   File : unlock.js
 *   Author URI : https://evoqins.com
 *   Description : Unlock modal
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { GradientButton, SecondaryButton } from "../Cta";
import Icon from "../Icon/icon";
import { OTPInput } from "../FormElements";

import style from "../../Styles/Components/unlock.module.scss";

import { closeModal } from "../../Helper/helper-function";

import APIService from "../../Services/api-service";
import { toast } from "react-toastify";

const DeleteUser = (props) => {

    const firstPINref = useRef(null);
    const navigator = useNavigate();
    const [pinString, setPinString] = useState("");

    const [errorMessage, setErrorMessage] = useState('');
    const [buttonLoader, setButtonLoader] = useState(false);
    const [seconds, setSeconds] = useState(30);

    // modal close listener
    useEffect(() => {
        const my_modal = document.getElementById("delete-user");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
            setPinString('');
        });
    }, []);

    useEffect(() => {
        var my_modal = document.getElementById("delete-user");
        my_modal.addEventListener("shown.bs.modal", () => {
            setSeconds(29);
        });
    }, []);

    useEffect(() => {
        const countdown = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            } else {
                clearInterval(countdown);
                // Handle expiration logic here (e.g., show a resend button)
            }
        }, 1000);

        return () => {
            clearInterval(countdown);
        };
    }, [seconds]);

    // handle function pin input
    const _handlePin = (value) => {
        setPinString(value);
        setErrorMessage("");
    }

    function _handleResend() {
        let url = 'user/delete';
        let payload = JSON.stringify({
            customer_id: props.userId,
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.success('Successfully OTP send', {
                    type: 'success'
                });
                setPinString("");
                setSeconds(30);
            } else {
                toast.error(response.message, {
                    type: 'error'
                })
            }
        })

    }

    // submit button click 
    async function _verifyPin() {
        if (pinString.length !== 4) {
            setErrorMessage("Please enter OTP");
            return;
        }
        setButtonLoader(true);
        let url = 'user/verify-delete';
        let payload = JSON.stringify({
            customer_id: props.userId,
            otp: pinString
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.success('Successfully deleted the user', {
                    type: 'success'
                });
                const close_button = document.getElementById("close-modal");
                close_button.click();
                navigator(-1);
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setButtonLoader(false);
        });
    }

    return (
        <div id="delete-user"
            // data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="active-sip"
            aria-hidden="true"
            className={`modal fade ${style.e_unlock}`}>
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content padding-32px-all  ${style.e_content} `}>
                    <button className="d-none" data-bs-dismiss="modal" id="close-modal"></button>
                    <div className="col-12 d-flex justify-content-between">

                        <Icon icon="close"
                            size={24}
                            className="cursor-pointer ms-auto e-modal-close-btn"
                            data-bs-dismiss="modal" />
                    </div>

                    <img draggable={false} src={require("../../Assets/Images/Modal/pin_illustration.png")}
                        alt="Kyc success"
                        width={72}
                        height={72}
                        className="mx-auto" />


                    <h6 className="color-eerie-black e-poppins-medium e-font-18 mt-4 mb-0 text-center">
                        Two factor authentication
                    </h6>
                    <p className="color-outer-space e-poppins-regular e-font-14 mt-2 mb-0 px-5 letter-spacing-point28 text-center">
                        To delete the user you need to confirm the verification code that sent to your email
                    </p>




                    <div className="d-flex flex-column align-items-center text-start mt-3 mb-2">
                        <OTPInput pinString={pinString}
                            id="sip-otp"
                            autoFocus={true}
                            pinError={errorMessage}
                            disabled={buttonLoader}
                            handleChange={_handlePin} />
                    </div>

                    <div className="d-flex  justify-content-center mt-4 w-100">

                        <SecondaryButton label="Cancel"
                            className="padding-12px-tb e-font-16 "
                            cancel="modal"
                        />
                        <GradientButton label="Confirm delete"
                            loading={buttonLoader}
                            className="padding-12px-tb e-font-16 ms-3"
                            onPress={_verifyPin}
                        />
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                        {
                            seconds === 0 ?
                                <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-pointer mb-0 e-forgot-pin position-relative w-max-content resend'
                                    onClick={_handleResend}>Resend OTP</p>
                                :
                                <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-not-allowed mb-0 '>
                                    Resend OTP in <span className="color-black">{seconds}s</span>
                                </p>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteUser;