/*
 *   File : order-list.js
 *   Author URI : https://evoqins.com
 *   Description : Order listing page
 *   Integrations : null
 *   Version : v1.1
 */

/* import packages */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

/* import components */
import Icon from "../../../../Components/Icon/icon";
import { CustomLoader, EmptyScreen } from "../../../../Components/Other";
import { DataTableContainer } from "../../../../Components/FormElements";

/* import styles */
import Colors from '../../../../Styles/color.module.scss';

// import services
import APIService from "../../../../Services/api-service";
import { _sortDate } from "../../../../Helper/helper-function";


const TABLE_STYLE = {
    table: {
        style: {
            padding: "0 16px 16px 16px",
            background: Colors.white,
        },
    },
    tableBody: {
        style: {
            margin: '0px',
            marginTop: "0px !important"
        }
    },
    headRow: {
        style: {
            background: 'transparent',
            borderRadius: "16px 16px 0px 0px",
            border: "0",
            borderBottom: `1px solid ${Colors.bright_gray} !important`,
            fontSize: "14px",
            fontFamily: "Poppins-Medium",
            lineHeight: "24px",
            color: Colors.black
        }
    },
    rows: {
        style: {
            margin: "0",
            background: "transparent",
            border: `1px solid ${Colors.bright_gray} !important`,
            borderRadius: "16px",
            marginTop: "14px",
            fontSize: "16px",
            fontFamily: "Poppins-Regular",
            lineHeight: "24px",
            cursor: "pointer",
            color: Colors.black,
            '&:hover': {
                boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
            },
        },
    },
    cells: {
        style: {
            '&:not(:last-child)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                display: 'flex',
                justifyContent: `start !important`
            },
            '&:first-child': {
                padding: "24px 0px 24px 24px",
            }
        },
    },
    headCells: {
        style: {
            '&:not(:last-child)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                display: 'flex',
                justifyContent: `start !important`
            },
            '&:first-child': {
                padding: "24px 0px 24px 24px",
            }
        },
    },
    pagination: {
        style: {
            borderTop: `none `,
            color: Colors.outer_space,
            fontSize: '16px',
            fontFamily: "Poppins-Regular",
        },
    }
}

const OrderList = React.memo((props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [orderList, setOrderList] = useState([]);
    const [loader, setLoader] = useState(true);
    const [paginationData, setPaginationData] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [pageSize, setPageSize] = useState(20);
    const [totalLength, setTotalLength] = useState(null);

    useEffect(() => {
        _getOrderList();
        //eslint-disable-next-line
    }, [pageNumber, pageSize])

    const _handleCardClick = (row) => {
        if (row.is_goal === true) {
            navigate("/orders/goal-order-detail", {
                state: {
                    customer_id: location.state.user_id,
                    order_id: row.order_id
                }
            })
        }
        else {
            navigate("/orders/fund-order-detail", {
                state: {
                    customer_id: location.state.user_id,
                    order_id: row.order_id
                }
            })
        }
    }

    const _handleRowHover = (row) => {

    }

    const _handleRowRemoveHover = () => {

    }

    // handler for page change
    function _nextPage() {
        setPageNumber(prevCount => prevCount + 1);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    // got to previous page
    function _previousPage() {
        setPageNumber(prevCount => prevCount - 1);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    const ORDER_COLUMNS = [
        {
            name: 'Order.ID',
            selector: row => row['order_no'],
            sortable: true,
            left: true,
            // width: "576px",
            maxWidth: "140px",
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name ps-2" onClick={() => _handleCardClick(row)}>
                    <p className="color-primary-color e-poppins-regular e-font-16 line-height-24px mb-0 bg-white mt-0">{row['order_no']}</p>
                </div>
        },
        {
            name: 'Invested in',
            selector: row => row['name'],
            sortable: false,
            left: true,
            minWidth: "360px",
            cell: row => <span onClick={() => _handleCardClick(row)}> {row['name']}</span>,
        },
        {
            name: 'Order date',
            selector: row => row['order_date'],
            sortable: true,
            left: true,
            maxWidth: "180px",
            sortFunction: (a, b) => _sortDate(a.order_date, b.order_date),
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0 mt-0 gap-12px" onClick={() => _handleCardClick(row)}>
                    {row['order_date']}
                </p>
        },
        {
            name: 'Type',
            selector: row => row['type'],
            sortable: true,
            left: true,
            maxWidth: "150px",
            cell: row => <span onClick={() => _handleCardClick(row)}> {row['type']}</span>,
        },
        {
            name: 'Amount',
            selector: row => row['amount'],
            sortable: true,
            left: true,
            maxWidth: "150px",
            cell: row => <span onClick={() => _handleCardClick(row)}>
                <span className="e-inter-regular">₹</span>
                {row['amount'].toLocaleString('en-IN')}</span>,
        },

        {
            name: 'Order status',
            selector: row => row['order_status'],
            sortable: false,
            left: true,
            cell: row =>
                <div onClick={() => _handleCardClick(row)}>
                    {
                        row['order_status'] === 'Failed' ?
                            <div className="d-flex align-items-center justify-content-center"
                                onClick={() => _handleCardClick(row)}>
                                <p className="color-lust e-poppins-medium e-font-14 line-height-18px mb-0 me-1">
                                    {row['order_status']}
                                </p>
                                <img src={require("../../../../Assets/Images/Orders/failed.png")} alt="Failed"
                                    width={24}
                                    height={24}
                                    draggable={false} />
                            </div>
                            :
                            row['order_status'] === "Success" ?
                                <div className="d-flex align-items-center justify-content-center "
                                    onClick={() => _handleCardClick(row)}>
                                    <p className="color-sea-green e-poppins-medium e-font-14 line-height-18px mb-0 me-1">
                                        {row['order_status']}
                                    </p>
                                    <img src={require("../../../../Assets/Images/Orders/success.png")} alt="Success"
                                        width={24}
                                        height={24}
                                        draggable={false} />
                                </div>
                                :
                                row['order_status'] === "Payment pending" ?
                                    <div className="d-flex align-items-center justify-content-center"
                                        onClick={() => _handleCardClick(row)}>
                                        <p className="color-sunset-blaze e-poppins-medium e-font-14 line-height-18px mb-0 me-1">
                                            {row['order_status']}
                                        </p>
                                        <img src={require("../../../../Assets/Images/Orders/payment-pending.svg").default} alt="Processing"
                                            width={24}
                                            height={24}
                                            draggable={false} />
                                    </div>
                                    :
                                    <div className="d-flex align-items-center justify-content-center"
                                        onClick={() => _handleCardClick(row)}>
                                        <p className="color-yellow-orange e-poppins-medium e-font-14 line-height-18px mb-0 me-1">
                                            {row['order_status']}
                                        </p>
                                        <img src={require("../../../../Assets/Images/Orders/processing.png")} alt="Processing"
                                            width={24}
                                            height={24}
                                            draggable={false} />
                                    </div>
                    }
                </div>
        },

    ];

    // API - Get user data
    function _getOrderList() {
        let url = 'user/order/list';
        let payload = JSON.stringify({
            customer_id: location.state.user_id,
            page_num: pageNumber,
            page_size: pageSize
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setOrderList(response.data.data);
                let total_pages = Math.ceil(response.data.number_of_records / response.data.page_size);
                setTotalPages(total_pages);
                setPaginationData({
                    rowsPerPageText: `${response.data.page} | Total records: ${response.data.number_of_records}`,
                });
                setTotalLength(response.data.number_of_records);
            }
            else {
                setOrderList([]);
            }
            setLoader(false);
        })
    }

    return (
        loader === true ?
            <div className="row h-60vh">
                <CustomLoader />
            </div>
            :
            orderList.length !== 0 ?
                <div className="col-12 position-relative pt-4">
                    <DataTableContainer columns={ORDER_COLUMNS}
                        data={orderList}
                        selectableRows={false}
                        pagination={true}
                        customStyles={TABLE_STYLE}
                        defaultSortFieldId={1}
                        onRowMouseEnter={_handleRowHover}
                        onRowMouseLeave={_handleRowRemoveHover}
                        paginationTotalRows={totalLength}
                        paginationComponentOptions={paginationData}
                        onChangeRowsPerPage={(e) => {
                            setPageSize(e);
                            setPageNumber(1);
                        }}
                        paginationPerPage={pageSize}
                        rowClick={(row) => {
                            _handleCardClick(row)
                        }} />
                    <div className={`d-flex justify-content-end align-items-center position-absolute bottom-16px right-24 w-max-content mt-3`}>
                        <Icon icon="pagination-previous" width="24px" height="24px"
                            className={pageNumber === 1 ? 'pointer-events-none opacity-4 mx-3' : 'mx-3 cursor-pointer'}
                            onClick={_previousPage} />
                        <Icon icon="pagination-next" width="24px" height="24px"
                            className={pageNumber === totalPages ? 'ms-1 pointer-events-none opacity-4' : 'ms-1 cursor-pointer'}
                            onClick={_nextPage} />
                    </div>
                </div>
                :
                <EmptyScreen title="Empty order data!" />
    )
})

export default OrderList;