/*
 *   File : nominee-otp.js
 *   Author URI : https://evoqins.com
 *   Description : Nominee OTP modal from KYC flow
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

import { GradientButton } from "../Cta";
import { Icon } from "../Icon";
import { OTPInput } from "../FormElements";

import APIService from "../../Services/api-service";

import style from '../../Styles/Components/unlock.module.scss';


const NomineeOTPVerification = (props) => {

    const firstPINref = useRef(null);
    const location = useLocation();

    const [pinString, setPinString] = useState("");
    const [buttonLoader, setButtonLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [seconds, setSeconds] = useState(30);

    useEffect(() => {
        var my_modal = document.getElementById("nominee-otp");
        my_modal.addEventListener("shown.bs.modal", () => {
            setSeconds(29);
        });
    }, []);

    useEffect(() => {
        const countdown = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            } else {
                clearInterval(countdown);
                // Handle expiration logic here (e.g., show a resend button)
            }
        }, 1000);

        return () => {
            clearInterval(countdown);
        };
    }, [seconds]);


    // modal close listener
    useEffect(() => {
        const my_modal = document.getElementById("nominee-otp");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
            setPinString('');
        });
    }, []);

    // update button loading status 
    useEffect(() => {
        setButtonLoader(props.loader);
    }, [props.loader]);

    useEffect(() => {
        const _handleKeyPress = (event) => {
            // Check if Ctrl key (or Command key on Mac) is pressed and 'a' key is pressed
            if (event.ctrlKey && event.key === 'a') {
                event.preventDefault(); // Prevent the default behavior (text selection)
            }
        };

        // Attach the event listener to the modal content
        const modalContent = document.getElementById('nominee-otp');

        modalContent.addEventListener('keydown', _handleKeyPress);

        // Clean up the event listener when the component is unmounted
        return () => {
            modalContent.removeEventListener('keydown', _handleKeyPress);
        };
    }, []);

    // handle function pin input
    const _handlePin = (value) => {
        setPinString(value);
        setErrorMessage("");
    }

    function _handleResend() {
        let url = 'kyc/nominee/confirm';
        let payload = JSON.stringify({
            is_nominee_available: props.optInStatus,
            nominee: props.optInStatus === true ? props.nominee : [],
            customer_id: location.state.user_id
        });

        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("OTP sent successfully", {
                    type: "success",
                });
                setPinString("");
                setSeconds(30);

            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
        });
    }


    // submit button click 
    async function _verifyPin() {
        if (pinString.length !== 4) {
            setErrorMessage("Please enter OTP");
            return;
        }
        setButtonLoader(true);
        try {
            const result = await props.handleOTPSubmission(pinString);
            // closing modal after api call;
            if (result === true) {
                const close_button = document.getElementById("close-modal");
                close_button.click();
                props.handleSuccess();

            }

        } catch (error) {

        }
        setButtonLoader(false);
    }

    return (
        <div id="nominee-otp"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="nominee-otp"
            aria-hidden="true"
            className={`modal fade ${style.e_unlock}`}>
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content p-4 ${style.e_content} `}>
                    <div className="d-flex justify-content-between align-items-center ">
                        <p className="color-eerie-black e-poppins-medium e-font-18 mb-0">
                            Nominee OTP verification
                        </p>
                        <Icon icon="close"
                            size={24}
                            data-bs-dismiss="modal"
                            className={` cursor-pointer e-modal-close-btn`} />
                    </div>

                    <p className="color-outer-space e-poppins-regular e-font-14 mb-4 margin-20px-top">
                        Enter OTP you have received in registered email and phone number
                    </p>

                    <button className="d-none" data-bs-dismiss="modal" id="close-modal"></button>
                    <img draggable={false} src={require("../../Assets/Images/Modal/pin_illustration.png")}
                        alt="Kyc success"
                        width={72}
                        height={72}
                        className="mx-auto mb-5 mt-3" />
                    <div className="d-flex flex-column align-items-center text-start">
                        <OTPInput pinString={pinString}
                            id="sip-otp"
                            autoFocus={true}
                            pinError={errorMessage}
                            disabled={buttonLoader}
                            handleChange={_handlePin} />

                        <div className="col-8">
                            <GradientButton label="Submit"
                                loading={buttonLoader}
                                className="w-100 padding-14px-tb margin-32px-top"
                                onPress={_verifyPin} />
                        </div>
                        <div className="d-flex justify-content-center mt-4">
                            {
                                seconds === 0 ?
                                    <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-pointer mb-0 e-forgot-pin position-relative w-max-content resend'
                                        onClick={_handleResend}>Resend OTP</p>
                                    :
                                    <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-not-allowed mb-0 '>
                                        Resend OTP in <span className="color-black">{seconds}s</span>
                                    </p>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

NomineeOTPVerification.defaultProps = {
    handleSuccess: () => { },
    handleOTPSubmission: () => { }
}

export default NomineeOTPVerification;