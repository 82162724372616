
/*
 *   File : index.js
 *   Author : https://evoqins.com
 *   Description : Helper file for utility functions 
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 05-09-2023
*/

import { useEffect } from "react";
import Colors from "../Styles/color.module.scss";

function GetURLVar() {
    let vars = [], hash;
    let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for (let i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return vars;
}

function useClickOutside(ref, callback) {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, callback]);
}

const useKeyHandler = (elementId, key, callback) => {
    useEffect(() => {
        const _handleKeyPress = (event) => {
            if (event.key === key) {
                callback(event);
            }
        };

        const element = document.getElementById(elementId);

        if (element) {
            element.addEventListener('keydown', _handleKeyPress);

            return () => {
                element.removeEventListener('keydown', _handleKeyPress);
            };
        }
    }, [elementId, key, callback]);
};

function closeModal(id) {
    var my_modal = document.getElementById(id);
    var modalBackdrop = document.querySelector(".modal-backdrop");
    my_modal.classList.remove("show");
    modalBackdrop.remove();
    my_modal.style.display = "none";
    document.body.removeAttribute("style");
}

function _formatNumber(number) {
    if (number >= 10000000) {
        const crores = number / 10000000; // 1 crore = 10,000,000
        return crores + 'Cr';
    } else if (number >= 100000) {
        const lakhs = number / 100000; // 1 lakh = 100,000
        return lakhs + 'L';
    } else {
        return number.toString();
    }
}

const _getColorForInvestmentStyle = (investmentStyle) => {
    switch (investmentStyle) {
        case 'Moderate':
            return Colors.mustard;
        case 'Conservative':
            return Colors.green_crayola;
        default:
            return Colors.begonia;
    }
};

function _sortDate(a, b) {
    const dateA = new Date(a);
    const dateB = new Date(b);

    if (dateA > dateB) {
        return 1;
    } else if (dateA < dateB) {
        return -1;
    } else {
        return 0;
    }

}


const _getDayOfMonth = (enabled_date = [], type=1) => {
    const today = new Date();
    const year = today.getFullYear(); // Get the current year
    const next_year = today.getFullYear();
    let day_of_month = today.getDate();
    let tomorrow = new Date(today);
    tomorrow.setDate(day_of_month + 1);
    let month = tomorrow.getMonth(); 
    let month_string =  tomorrow.toLocaleString('en-US', { month: 'short' });

     // check if tomorrow is > 28 then taking 1st of next month as tomorrow
     if (tomorrow.getDate() > 28) {
        month = today.getMonth() + 1 >= 11 ? 0 : today.getMonth() + 1; // next month
        const current_year = today.getMonth() + 1 >= 11 ? next_year : year;
        tomorrow = new Date(current_year, month, 1); 
        month_string =  tomorrow.toLocaleString('en-US', { month: 'short' });
    }
    

    // if (enabled_date.length != 0) {
        let date = _checkAndGetValidDate(tomorrow.getDate());
    // }
    
    function _checkAndGetValidDate (date) {
        let selected_date = date;
        let date_obj = new Date(year, month, date);
        let day_of_week = date_obj.getDay();

        if (date === undefined || date > 28) {  
            const next_month = month+1 > 11 ? 0 : month+1;
            if (enabled_date.length) {
                selected_date = parseInt(enabled_date[0]);
            }else{
                selected_date = 1;
            }
            
            const current_year = today.getMonth() + 1 >= 11 ? next_year : year;
             tomorrow = new Date(current_year, next_month, 1); 
            date_obj = new Date(current_year, next_month, selected_date);
            day_of_week = date_obj.getDay();
            month_string =  date_obj.toLocaleString('en-US', { month: 'short' });
        }
        let selected_date_string =  selected_date?.toString();
        // Check if the selected date is included in the enabled_date array
        if (enabled_date.length !== 0 && enabled_date.includes(selected_date_string) == false) {
            //Find the next enabled date that is greater than the current selected date
            selected_date = enabled_date.find(active_date => parseInt(active_date, 10) > selected_date);
            _checkAndGetValidDate(selected_date);
        }else{
            // If the selected date is a saturday, add 2 days to make it Monday
            if ( day_of_week === 6) {
                _checkAndGetValidDate(parseInt(selected_date)+2);
                
            }
             // If the selected date is a sunday, add 1 day to make it Monday
            else if (day_of_week === 0) { 
                _checkAndGetValidDate(parseInt(selected_date)+1);
            } 
            // If the selected date is valid and not a weekend, set it as the new date for tomorrow
            else{
                tomorrow.setDate(selected_date);
                return parseInt(selected_date);
            }
        }
    }
    return { date: tomorrow.getDate(), month: month_string };

};

// format array to id and label for invested Folio 
const _convertToIdLabelArray = folios => folios.map(item => ({ id: item.id, label: item.folio_number }));

// format array to id and label for kyc personal detail
const _convertToLabelIdArray = data => data.map(item => ({ id: item.id, label: item.name, image: item.image }));

function _isFileTypeAllowed(fileType) {
    console.log("filetype", [".pdf", ".jpeg", ".jpg", ".png", ".doc", ".docx"].includes(fileType), typeof (fileType))
    return [".pdf", ".jpeg", ".jpg", ".png"].includes(fileType);
}


export { GetURLVar, useClickOutside, closeModal, _formatNumber, _getColorForInvestmentStyle, useKeyHandler, _sortDate, _getDayOfMonth, _convertToIdLabelArray, _convertToLabelIdArray, _isFileTypeAllowed }; 