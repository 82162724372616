
/*
 *   File : create-user.js
 *   Author URI : https://evoqins.com
 *   Description : Modal for creating user account
 *   Integrations : null
 *   Version : v1.1
 */

/* import packages */
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

/* import components */
import { GradientButton, SecondaryButton } from "../Cta";
import { Icon } from "../Icon";
import { CustomTextInput, RadioGroup, CustomSearchSelectBox, OTPInput, CustomPhoneInput } from "../FormElements";

//  service
import APIService from "../../Services/api-service";


// import style
import style from '../../Styles/Components/create-user.module.scss';

const RELATION = [
    { value: 1, label: 'Self' },
    { value: 2, label: 'Father' }
]

export default function CreateUserAccount(props) {
    const navigator = useNavigate();

    const [userNameType, setUserNameType] = useState(2);
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [mobile, setMobile] = useState("");
    const [mobileError, setMobileError] = useState("");
    const [relation, setRelation] = useState();
    const [relationOption, setRelationOption] = useState(RELATION);
    const [relationError, setRelationError] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [pin, setPin] = useState("");
    const [pinError, setPinError] = useState("");
    const [verificationScreen, setVerificationScreen] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");
    const [seconds, setSeconds] = useState(30);

    useEffect(() => {
        var my_modal = document.getElementById("create-user");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
        //eslint-disable-next-line
    }, [props.close]);

    useEffect(()=>{
        _getRelations();
    },[]);

    useEffect(() => {
        const countdown = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            } else {
                clearInterval(countdown);
                // Handle expiration logic here (e.g., show a resend button)
            }
        }, 1000);

        return () => {
            clearInterval(countdown);
        };
    }, [seconds]);



    // handle radio 
    function _handleRadioInput(e) {
        setUserNameType(e);
    }

    // handle name input change
    function _handleNameInput(e) {
        setName(e);
        setNameError("");
    }

    // handle email change
    function _handleEmailInput(e) {
        setEmail(e);
        setEmailError("");
    }

    // handle mobile change
    function _handleMobileInput(e) {
        setMobile(e);
        setMobileError("");
    }

    // handle relation dropdown
    function _handleRelation(e) {
        setRelation(e);
        setRelationError("");
    }

    // handle password
    function _handlePassword(e) {
        setPassword(e);
        setPasswordError("");
    }

    // handle pin 
    function _handlePin(e) {
        setPin(e);
        setPinError("");
    }

    // handle otp
    function _handleOTP(e) {
        setOtp(e);
        setOtpError('');
    }

    // Form validation
    function _validateForm() {
        console.log('ruwi')
        let valid = true;
        if (name === "") {
            setNameError("Name is required");
            valid = false;
        }
        if (userNameType === 2) {
            if (email === "") {
                setEmailError('Email is required');
                valid = false;
            }
            else if ( !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
                setEmailError('Enter valid Email');
                valid = false;
            }
        }
        if (userNameType === 1 ) {
            if (mobile === "") {
                setMobileError('Mobile is required');
                valid = false;
            }
            else if (!/^[6-9]\d*$/.test(mobile)) {
                valid = false;
                setMobileError("Enter valid Mobile");
            }
        }

        if (relation === null) {
            setRelationError('Relation is required');
            valid = false;
        }

        if (password.length < 4) {
            setPasswordError('Enter valid password');
            valid = false;
        }
        if (pin === "") {
            setPinError('Pin is required');
            valid = false;
        }

        if (valid === true) {
            _signUp();
        }
    }

    // API - get signup relation
    function _getRelations() {
        let url = 'user/signup-relation';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                let relation_data = response.data.map((item) => {
                    return ({ value: item.id, label: item.name })
                });
                setRelationOption(relation_data);
                setRelation(relation_data[0]);
            }
        })
    }

    // API - signup user
    function _signUp() {
        setButtonLoader(true);
        let url = 'user/create-customer';
        let payload = JSON.stringify({
            type: userNameType,
            name,
            username: userNameType === 1 ? mobile : email,
            relation: relation.value,
            password,
            pin
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.success(`OTP sent to ${userNameType === 1 ? mobile : email}`, {
                    type: 'success'
                });
                setVerificationScreen(true);
                setSeconds(29);
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setButtonLoader(false);
        })
    };

    // API - resend OTP 
    function _handleResend() {
        let url = 'user/resend-otp';
        let payload = JSON.stringify({
            username: userNameType === 1 ? mobile : email,
            country_code: null,
            relation: relation.value
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("OTP sent successfully", {
                    type: "success",
                });
                setOtp("");
                setSeconds(30);
            }else{
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
        })
    }

    // API - verify OTP
    function _verifyPin() {
        if (otp === "") {
            setOtpError('OPT is required');
            return;
        }
        setButtonLoader(true);
        let url = 'user/verify-otp';
        let payload = JSON.stringify({
            otp,
            type: "SIGNUP",
            username: userNameType === 1 ? mobile : email,
            relation: relation.value
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.success('Successfully created the user', {
                    type: 'success'
                });
                const close_button = document.getElementById("close-modal");
                close_button.click();
                navigator('/create-account', {
                    state: {
                        user_id: response.data
                    }
                });
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setButtonLoader(false);
        })
    }

    return (
        <div id="create-user"
            // data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="active-sip"
            aria-hidden="true"
            className={`modal fade ${style.e_create_user}`}>
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content  bg-white position-relative text-center d-flex align-items-center p-4  ${style.e_content} `}>
                    <button className="d-none" data-bs-dismiss="modal" id="close-modal"></button>
                    <div className="col-12 d-flex justify-content-between">
                        <p className="color-jett-black e-poppins-semi-bold e-font-18 mb-0">Create user</p>
                        <Icon icon="close"
                            size={24}
                            className="cursor-pointer ms-auto e-modal-close-btn"
                            data-bs-dismiss="modal" />
                    </div>

                    {
                        verificationScreen === false ?
                            <div className={`row ${style.content_wrapper}`} id="modal-body">
                                <div className="margin-16px-top d-lg-flex d-md-flex d-block gap-20px">
                                    <p className="color-black e-poppins-regular e-font-16 line-height-16px mb-2 mt-2">Create account with</p>
                                    <div className="d-flex align-items-center gap-8px cursor-pointer"
                                        onClick={() => _handleRadioInput(2)}>
                                        <Icon icon={userNameType === 2 ? "radio-selected" : "radio-unselected"}
                                            size={20} />
                                        <p className="color-outer-space e-poppins-regular e-font-14 line-height-24px mb-0">Mail</p>
                                    </div>
                                    <div className="d-flex align-items-center gap-8px cursor-pointer mt-lg-0 mt-md-0 mt-3"
                                        onClick={() => _handleRadioInput(1)}>
                                        <Icon icon={userNameType === 1 ? "radio-selected" : "radio-unselected"}
                                            size={20} />
                                        <p className="color-outer-space e-poppins-regular e-font-14 line-height-24px mb-0">Mobile</p>
                                    </div>

                                </div>
                                <div className="col-12 mt-3">
                                    <CustomTextInput label="Name"
                                        postfix="*"
                                        personName={true}
                                        id={`name-input`}
                                        value={name}
                                        error={nameError}
                                        valueChange={_handleNameInput} />
                                </div>
                                <div className="col-12 mt-4 pt-2">
                                    {
                                        userNameType === 2 ? (
                                            <CustomTextInput label="Email"
                                                postfix="*"
                                                id={`email-input`}
                                                value={email}
                                                error={emailError}
                                                valueChange={_handleEmailInput} />
                                        ) : (
                                            <CustomPhoneInput label="Phone"
                                                value={mobile}
                                                postfix="*"
                                                id='number-input'
                                                type="number"
                                                error={mobileError}
                                                handleChange={_handleMobileInput} />
                                        )
                                    }
                                </div>
                                <div className="col-12 text-start">
                                    <CustomSearchSelectBox value={relation ? relation : ""}
                                        label="Relation"
                                        error={relationError}
                                        postfix="*"
                                        className="margin-32px-bottom margin-32px-top"
                                        options={relationOption}
                                        // onMenuToggle={_handleDropdownOpen}
                                        onSelectChange={_handleRelation} />
                                </div>
                                <div className="col-12">
                                    <CustomTextInput label="Password"
                                        postfix="*"
                                        type={showPassword ? "text" : "password"}
                                        // hide_error_text={true}
                                        value={password}
                                        error={passwordError}
                                        password_icon={true}
                                        isAuthInput={true}
                                        showPassword={() => {
                                            setShowPassword(!showPassword)
                                        }}
                                        valueChange={_handlePassword} />
                                </div>
                                <div className="col-12 mt-3 pt-3">
                                    <CustomTextInput label="Pin"
                                        postfix="*"
                                        type={'number'}
                                        max={4}
                                        value={pin}
                                        error={pinError}
                                        valueChange={_handlePin} />
                                    {/* <OTPInput pinString={pin}
                                id="sip-otp"
                                autoFocus={true}
                                pinError={pinError}
                                handleChange={_handlePin} /> */}
                                </div>
                                <div className="col-12">
                                    <SecondaryButton label="Cancel"
                                        cancel="modal" />
                                    <GradientButton label="Create account"
                                        loading={buttonLoader}
                                        className=" padding-14px-tb ms-3 margin-32px-top"
                                        onPress={_validateForm} />
                                </div>
                            </div>
                            :
                            <div className={`row ${style.content_wrapper} mt-5`} id="modal-body">
                                <p className="color-outer-space e-poppins-regular e-font-16 mb-4 margin-20px-top">
                                    {`Enter OTP received in ${userNameType === 2 ? email: `+91 ${mobile}`}`}
                                </p>
                                <div className="col-12">
                                <img draggable={false} src={require("../../Assets/Images/Modal/pin_illustration.png")}
                                    alt="Kyc success"
                                    width={72}
                                    height={72}
                                    className="mx-auto mb-5 mt-3" />
                                </div>
                                <div className="col-12">
                                    <OTPInput pinString={otp}
                                        id="sip-otp"
                                        autoFocus={true}
                                        pinError={otpError}
                                        handleChange={_handleOTP} />
                                </div>
                                <div className="col-8 offset-2">
                                    <GradientButton label="Submit"
                                        loading={buttonLoader}
                                        className="w-100 padding-14px-tb margin-32px-top"
                                        onPress={_verifyPin} />
                                </div>
                                <div className="d-flex justify-content-center mt-4">
                                    {
                                        seconds === 0 ?
                                            <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-pointer mb-0 e-forgot-pin position-relative w-max-content resend'
                                                onClick={_handleResend}>Resend OTP</p>
                                            :
                                            <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-not-allowed mb-0 '>
                                                Resend OTP in <span className="color-black">{seconds}s</span>
                                            </p>
                                    }

                                </div>
                            </div>

                    }

                </div>
            </div>
        </div>
    )
} 