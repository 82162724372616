
/*
 *   File : add-bank-success.js
 *   Author URI : https://evoqins.com
 *   Description : Modal component for add bank success
 *   Integrations : null
 *   Version : v1.1
 */
import { GradientButton, SecondaryButton } from '../../Cta';

import style from '../../../Styles/Components/add-bank.module.scss';

export default function AddBankSuccess({ loader, verificationType, status, ...props }) {
    return (
        <div className={`container gx-0 ${style.e_scroll_container}`}>
            {
                // Loader screen 
                loader === true ? (
                    <div className="row">
                        <div className="col-8 offset-2">
                            <img src={require('../../../Assets/Images/Profile/phonePay.gif')}
                                alt=""
                                className={`${style.e_loading_gif} mt-5`} />
                            <h6 className="color-eerie-black e-font-18 e-poppins-medium margin-block-end-0 mt-3">
                                Verifying the bank with penny drop
                            </h6>
                            <p className="color-outer-space e-poppins-regular e-font-14 mt-2 pt-1">
                                This will take a few seconds.
                            </p>
                        </div>
                    </div>
                ) :
                    // Verification success screen
                    (
                        <div className="row mt-5 pt-5">
                            <div className="col-8 offset-2 text-center h-300px mt-4">

                                <img src={status === false ? require('../../../Assets/Images/Orders/order-failed.svg').default : require('../../../Assets/Images/Profile/success.svg').default}
                                    alt=""
                                    className='mt-4' />

                                <h6 className="color-eerie-black e-font-18 e-poppins-medium mt-4">
                                    {
                                        status === false ?
                                            'Bank account verification failed'
                                            :
                                            'Bank account verified successfully.'
                                    }

                                </h6>
                                {/* Showing description only in UPI verification */}
                                {
                                    verificationType === 1 && status === true && (
                                        <p className="color-outer-space e-font-16 e-inter-regular">
                                            ₹1 debited from your account and will get refunded within 24 hours.
                                        </p>
                                    )
                                }
                            </div>
                            {
                                status === true ? (
                                    <div className="col-lg-8 col-12 offset-lg-2 mt-3 position-relative bottom-0">
                                        {
                                            props.kycFlow === false &&
                                            <SecondaryButton label="Close"
                                                cancel="modal" />
                                        }
                                        <GradientButton label={`${props.kycFlow === true ? "Continue" : "Start investing"}`}
                                            className={`padding-14px-tb padding-60px-lr ms-3`}
                                            cancel="modal"
                                            onPress={() => {
                                                if (props.kycFlow === true) {
                                                    props.updateBank()
                                                }
                                            }} />
                                    </div>
                                ) : (
                                    <div className="col-8 offset-2 mt-3 position-relative bottom-0">
                                        <SecondaryButton label="Close"
                                            cancel="modal" />
                                    </div>
                                )
                            }


                        </div>
                    )
            }

        </div>
    )
};