/*
 *   File : user-listing.js
 *   Author URI : https://evoqins.com
 *   Description : User listing page
 *   Integrations : null
 *   Version : v1.1
 */

/* import packages */
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { Modal } from "bootstrap";

/* import components */
import { NavigationHeader } from "../../../Components/Header";
import { CustomDateRangePicker, DataTableContainer, SearchInput } from "../../../Components/FormElements";
import { UserFilter } from "../../../Components/Popups";
import { DownloadButton, GradientButton } from "../../../Components/Cta";
import Icon from "../../../Components/Icon/icon";
import { UserSummary } from "../../../Components/Cards";
import { SectionTitle } from "../../../Components/Title";
import { CustomLoader, EmptyScreen } from "../../../Components/Other";
import { UnlockSIPModal } from "../../../Components/Modal";
import { toast } from "react-toastify";

/* import styles */
import Colors from '../../../Styles/color.module.scss';


/* import services */
import { useClickOutside } from "../../../Helper/helper-function";
import APIService from "../../../Services/api-service";
import { useSelector } from "react-redux";

const PAGE_NAVIGATION = [
    "Dashboard",
    "Users"
]



const SUMMERY_ICONS = [
    require('../../../Assets/Images/Users/registered.svg').default,
    require('../../../Assets/Images/Users/ac-opned.svg').default,
    require('../../../Assets/Images/Users/invested.svg').default,
    require('../../../Assets/Images/Users/not-invested.svg').default
];

const AdminUserListing = React.memo(() => {

    const navigate = useNavigate();
    const location = useLocation();
    const popupRef = useRef(null);
    const filterRef = useRef(null);

    const ADMIN_PROFILE_DATA = useSelector(state => state.MT_ADMIN_STORE.ADMIN_PROFILE);

    const [showFilter, setShowFilter] = useState(false);
    const [selectedRange, setSelectedRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });
    const [startDate, setStartDate] = useState("Start date");
    const [endDate, setEndDate] = useState("End date");
    const [showDateFilter, setShowDateFilter] = useState(false);
    const [filterData, setFilterData] = useState({});
    const [userSummaryData, setUserSummaryData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [accountType, setAccountType] = useState([]);
    const [status, setStatus] = useState([]);
    const [userList, setUserList] = useState([])
    const [loader, setLoader] = useState(true);
    const [initialLoader, setInitialLoader] = useState(true);
    const [downloadLoader, setDownloadLoader] = useState(false);
    const [unlockModal, setUnlockModal] = useState(false);
    const [paginationData, setPaginationData] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [pageSize, setPageSize] = useState(20);
    const [totalLength, setTotalLength] = useState(null);
    const [permissionType, setPermissionType] = useState('');
    const [filterCount, setFilterCount] = useState(0);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState(new Set());
    const [rowSelectFalse, setRowSelectFalse] = useState(false);

    useEffect(() => {
        _getFilterData();
        window.scrollTo({
            top: '0',
            behavior: 'smooth'
        });
    }, []);

    useEffect(() => {
        const page_type = ADMIN_PROFILE_DATA.permission_details.filter((permission) => location.pathname.includes(permission.short_name.toLowerCase().replace(/_/g, '-')))
        if (page_type.length > 0) {
            setPermissionType(page_type[0].action);
        }
    }, [ADMIN_PROFILE_DATA, location]);

    useEffect(() => {
        if (initialLoader === false) {
            _getUserList();
        }
    }, [accountType, status, pageNumber, initialLoader, pageSize, startDate, endDate])

    useEffect(() => {
        _getUserSummary();
    }, [startDate, endDate]);

    useEffect(() => {
        if (unlockModal === true) {
            const modal = new Modal(document.getElementById("unlock"));
            modal.show();
        }
    }, [unlockModal]);

    useEffect(() => {
        if (loader === false) {
            const body_element = document.getElementById("e-table-body")
            if (body_element) {
                if (showFilter === true) {
                    body_element.style.pointerEvents = 'none';
                    document.body.style.overflow = 'hidden';
                    window.scrollTo({
                        behavior: "smooth",
                        top: 200
                    });
                } else {
                    body_element.removeAttribute("style");
                    document.body.removeAttribute('style');
                }
            }
        }
    }, [showFilter, loader]);

    useEffect(() => {
        let count = 0;
        if (accountType.length !== 0 && !accountType.includes(null)) {
            count++;
        }
        if (status.length !== 0 && !status.includes(null)) {
            count++;
        }
        setFilterCount(count);
    }, [accountType, status])

    useClickOutside(filterRef, () => {
        setShowFilter(false);
    });


    function _handleDateRangeChange(newRange) {
        setShowDateFilter(false);
        setStartDate(format(newRange.startDate, "dd-MM-yyyy"));
        setEndDate(format(newRange.endDate, "dd-MM-yyyy"));
        setSelectedRange(newRange);
    };

    function _handleShowDateFilter() {
        setShowDateFilter(!showDateFilter);
    }

    // handler for page change
    function _nextPage() {
        setPageNumber(prevCount => prevCount + 1);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    // got to previous page
    function _previousPage() {
        setPageNumber(prevCount => prevCount - 1);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    function _handleClearDates() {
        setShowDateFilter(false);
        setSelectedRange({
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        });
        setStartDate("Start date");
        setEndDate("End date");
    }



    function _handleCardClick(row) {
        if (permissionType !== 'read') {
            navigate("/user-summary/customer-detail", {
                state: {
                    user_id: row.id
                }
            });
        }
    }

    function _handleRowHover(row) {

    }

    function _handleRowRemoveHover() {

    }

    function _handleToggleFilter() {
        setShowFilter(!showFilter);
    }

    function _handleSearchQuery(value) {
        setPageNumber(1);
        setSearchQuery(value);
        if (value === "") {
            _getUserList("")
        }
    }

    function _handleApplyFilter(amount, status) {
        setPageNumber(1);
        setShowFilter(false);
        setAccountType(amount);
        setStatus(status);
    }

    // API - Get filter data 
    const _getFilterData = () => {
        let url = 'user/filter-data';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setFilterData(response.data);
                setAccountType([response.data.account_type[0].id]);
                setStatus([response.data.status[0].id]);
                setInitialLoader(false)
            }
        })
    }

    // API - summary data
    const _getUserSummary = () => {
        let url = 'user/summary';
        let payload = JSON.stringify({
            start_date: startDate === "Start date" ? null : startDate,
            end_date: endDate === "End date" ? null : endDate
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                const modified_data = response.data.map(item => {
                    return {
                        ...item,
                        progress: item.progress.replace(/_/g, ' ') // Remove underscores from progress
                    };
                });
                setUserSummaryData(modified_data);
            }
        })
    }

    // API - search data
    const _getUserList = (query = searchQuery) => {
        setLoader(true);
        let url = 'user/list';
        let payload = JSON.stringify({
            query: query,
            invested_type: accountType.length === 0 ? null : accountType,
            status: status.length === 0 ? null : status,
            page_num: pageNumber,
            page_size: pageSize,
            start_date: startDate === "Start date" ? null : startDate,
            end_date: endDate === "End date" ? null : endDate
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                let total_pages = Math.ceil(response.data.number_of_records / response.data.page_size);
                setTotalPages(total_pages);
                setPaginationData({
                    rowsPerPageText: `${response.data.page} | Total records: ${response.data.number_of_records}`,
                });
                setTotalLength(response.data.number_of_records);

                setUserList(response.data.data);
            }
            else {
                setUserList([]);
            }
            setLoader(false);
        })
    }

    // API - confirm sip download
    const _confirmUserDownload = () => {
        setDownloadLoader(true);
        const customer_id = [...selectedUsers]
        const url = "user/confirm-download";

        const data = {
            "customer_ids": selectAll === true ? [] : customer_id
        }

        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setDownloadLoader(false);
                setUnlockModal(true);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        })
    }


    // API -download csv
    const _downloadUsers = (pin) => {
        setLoader(true);
        const customer_id = [...selectedUsers]
        const url = "user/download";

        const data = {
            "customer_ids": selectAll === true ? [] : customer_id,
            "otp": pin,
            "type": "DOWNLOAD"
        }

        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                window.open(response.data.url, "_blank");
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
            setLoader(false);
            setUnlockModal(false);
            setSelectedUsers(new Set());
            setSelectAll(false);
        })
    }

    const TABLE_STYLE = {
        table: {
            style: {
                padding: "12px 16px 16px 16px",
                background: Colors.white,
            },
        },
        tableBody: {
            style: {
                margin: '0px',
                marginTop: "0px !important"
            }
        },
        headRow: {
            style: {
                background: 'transparent',
                borderRadius: "16px 16px 0px 0px",
                border: "0",
                borderBottom: `1px solid ${Colors.bright_gray} !important`,
                fontSize: "14px",
                fontFamily: "Poppins-Medium",
                lineHeight: "24px",
                color: Colors.outer_space
            }
        },
        rows: {
            style: {
                margin: "0",
                background: "transparent",
                border: `1px solid ${Colors.bright_gray} !important`,
                borderRadius: "16px",
                marginTop: "14px",
                fontSize: "16px",
                fontFamily: "Poppins-Regular",
                lineHeight: "24px",
                cursor: permissionType !== 'read' && "pointer",
                '&:hover': {
                    boxShadow: permissionType !== 'read' && '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
                },
            },
        },
        cells: {
            style: {
                '&:not(:last-child)': {
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    display: 'flex',
                },
                '&:first-child': {
                    padding: "24px 0px 24px 24px",
                }
            },
        },
        headCells: {
            style: {
                '&:not(:last-child)': {
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    display: 'flex',
                },
                '&:first-child': {
                    padding: "24px 0px 24px 24px",
                }
            },
        },
        pagination: {
            style: {
                borderTop: `none `,
                color: Colors.outer_space,
                fontSize: '16px',
                fontFamily: "Poppins-Regular",
            },
        }
    }


    const _handleSelectAll = (event) => {
        event.stopPropagation();
        setSelectAll(!selectAll)
    }

    const _handleRowCheckBox = (row, event) => {
        // console.log("Row", row);
        event.stopPropagation();
        // console.log("before", selectedUsers)
        if (selectedUsers.has(row.id)) {
            setSelectedUsers((prevCustomerIds) => {
                const new_customer_ids = new Set(prevCustomerIds);
                new_customer_ids.delete(row.id);
                return new_customer_ids;
            });
        }
        else {
            setSelectedUsers((prevCustomerIds) => new Set([...prevCustomerIds, row.id]));
        }
        setRowSelectFalse(true);
    }

    // for deselecting header checkbox in the table
    useEffect(() => {
        if (rowSelectFalse === true) {
            setSelectAll(false);
        }
    }, [rowSelectFalse])

    useEffect(() => {
        if (selectAll === true) {
            const customer_id_set = new Set(userList.map((item) => item.id));
            setSelectedUsers((prevSet) => new Set([...prevSet, ...customer_id_set]));
            setRowSelectFalse(false);
        }
        else {
            if (rowSelectFalse === false) {
                setSelectedUsers(new Set());
            }
        }
    }, [selectAll, userList]);

    const USER_COLUMNS = [

        {
            name: <div className={`position-relative w-fit-content ${permissionType !== 'read' && 'padding-40px-left'}`}>
                {/* Add your custom image or icon here */}
                {
                    permissionType !== 'read' && (
                        <Icon icon={selectAll === true ? "checked" : "unchecked"}
                            size={22}
                            className="position-absolute left-10 cursor-pointer"
                            onClick={(event) => _handleSelectAll(event)} />
                    )
                }

                <p className="mb-0">Cus.ID</p>
            </div>,
            selector: row => row['id_text'],
            sortable: true,
            left: true,
            width: "255px",
            // maxWidth: "250px",
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name ps-2" onClick={() => _handleCardClick(row)}>
                    {
                        permissionType !== 'read' && (
                            <Icon icon={selectedUsers.has(row['id']) ? "checked" : "unchecked"}
                                size={22}
                                onClick={(event) => _handleRowCheckBox(row, event)} />
                        )
                    }

                    <p className="color-primary-color e-poppins-regular e-font-16 line-height-24px mb-0 bg-white mt-0">{row['id_text']}</p>
                </div>
        },
        {
            name: 'Name',
            selector: row => row['name'],
            sortable: false,
            left: true,
            minWidth: "180px",
            cell: row => <span onClick={() => _handleCardClick(row)} className="color-outer-space"> {row['name']}</span>,
        },
        {
            name: 'Created on',
            selector: row => row['created'],
            sortable: true,
            left: true,
            minWidth: "200px",
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0 mt-0 gap-12px color-outer-space" onClick={() => _handleCardClick(row)}>
                    {row['created']}
                </p>
        },
        {
            name: 'Email',
            selector: row => row['email'],
            sortable: false,
            left: true,
            minWidth: "350px",
            cell: row => <span onClick={() => _handleCardClick(row)} className="color-outer-space"> {row['email']}</span>,
        },
        {
            name: 'Phone',
            selector: row => row['phone'],
            sortable: false,
            left: true,
            minWidth: "150px",
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0 mt-0 color-outer-space" onClick={() => _handleCardClick(row)}>
                    {row['phone']}
                </p>
        },
        {
            name: 'Status',
            selector: row => row['active'],
            sortable: false,
            center: true,
            maxWidth: "100px",
            cell: row =>
                <div onClick={() => _handleCardClick(row)}>
                    {
                        row.active === true ?
                            <p className="color-sea-green e-poppins-medium e-font-16 line-height-24px ps-2 mb-0 mt-0">
                                Active
                            </p>
                            :
                            <p className="color-lust e-poppins-medium e-font-16 line-height-24px ps-2 mb-0 mt-0">
                                In-active
                            </p>
                    }
                </div>
        },
        {
            name: 'Invested',
            selector: row => row['invested_amount'],
            sortable: false,
            center: true,
            width: "170px",
            cell: row =>
                <div onClick={() => _handleCardClick(row)}>
                    <p className="color-outer-space e-poppins-regular e-font-16 line-height-24px mb-0 mt-0">
                        {
                            row['invested_amount'] ?
                                <>
                                    <span className="e-inter-regular">₹</span>
                                    {row['invested_amount'].toLocaleString('en-IN')}
                                </>
                                :
                                '-'
                        }

                    </p>
                </div>
        },

    ];

    // custom checkbox
    const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
        return (
            <div className="position-relative me-3">

                <input
                    htmlFor="booty-check"
                    type="checkbox"
                    className="form-check-input border-6px-all w-20px h-20px border-color-bright-gray border-1px-solid cursor-pointer mt-0"
                    ref={ref}
                    onClick={onClick}
                    {...rest}
                />

            </div>
        )
    })

    return (
        <Fragment>
            <NavigationHeader type={1}
                data={PAGE_NAVIGATION}
                title="Users" />

            {

                <div className="ps-4 pe-lg-5 pe-md-2 pe-2 padding-32px-top">
                    <div className="row">
                        <div className="col-12 pb-3 d-flex justify-content-between align-items-center ">
                            <SectionTitle title="User summary" />
                            <div className="position-relative pe-1">
                                <CustomDateRangePicker
                                    startDate={startDate}
                                    endDate={endDate}
                                    popupRef={popupRef}
                                    showDateFilter={showDateFilter}
                                    handleShowDateFilter={_handleShowDateFilter}
                                    selectedRange={selectedRange}
                                    handleDateRangeChange={_handleDateRangeChange}
                                    clearDates={_handleClearDates} />
                            </div>
                        </div>
                        {
                            userSummaryData.map((item, key) =>
                                <div className="col-lg-3 col-md-6 col-6 pb-3"
                                    key={key}>
                                    <UserSummary type={1}
                                        title={item.progress}
                                        count={item.count}
                                        borderColor="bg-alice-blue"
                                        icon={SUMMERY_ICONS[key]} />
                                </div>
                            )
                        }

                        <div className="row d-flex justify-content-between mt-4 pe-0">
                            <div className="col-lg-3">
                                <SectionTitle title="Users list" />
                            </div>
                            <div className="col-lg-9 d-flex justify-content-end pe-0">
                                <SearchInput placeholder="Search with name, email, mobile, client ID"
                                    value={searchQuery}
                                    valueChange={_handleSearchQuery}
                                    handleSearch={_getUserList}
                                    divClass="w-50 ms-2 h-100" />
                                {
                                    permissionType === 'read' ?
                                        null
                                        :
                                        <DownloadButton onPress={_confirmUserDownload}
                                            // disabled={selectedSips.length !== 0 ? false : true}
                                            loader={downloadLoader} 
                                            className="ms-2"/>
                                }
                                <div className="position-relative ms-2" >
                                    {
                                        filterCount > 0 &&
                                        <span className="color-white border-radius-100px e-poppins-bold e-font-12 line-height-12px e-filter-badge position-absolute bg-sea-green d-flex justify-content-center align-items-center">{filterCount}</span>
                                    }
                                    <div className="border-radius-6px p-2 border-bright-gray border-all bg-white  d-flex align-items-center z-index-10 h-100"
                                        onClick={_handleToggleFilter}>
                                        <p className="color-charleston-green e-poppins-medium e-font-14 line-height-24px mb-0 pe-1 cursor-pointer mt-0">Filter</p>
                                        <img draggable={false} src={require('../../../Assets/Images/Users/filter.png')}
                                            alt="Filter"
                                            width={24}
                                            height={24}
                                            className="cursor-pointer me-2" />
                                    </div>
                                    {
                                        showFilter === true &&
                                        <Fragment>
                                            <div className="position-relative" ref={filterRef}>
                                                <UserFilter accountType={accountType}
                                                    status={status}
                                                    data={filterData}
                                                    applyFilter={_handleApplyFilter}
                                                    close={() => setShowFilter(false)} />
                                            </div>
                                            {/* <div className="position-fixed w-100 z-index-8 h-100 left-0 top-20px cursor-default"
                                                    onClick={() => {
                                                        console.log("is this gettgin run")
                                                        setShowFilter(false)
                                                    }}></div> */}
                                        </Fragment>
                                    }
                                </div>


                            </div>
                        </div>
                        <div className="col-12 text-end mt-2">
                            <p className="color-outer-space e-poppins-regular e-font-12 mb-0">
                                <img draggable={false} src={require('../../../Assets/Images/Onboarding/scroll-gif.gif')} alt="scroll"
                                    width='24px'
                                    height='24px'
                                    className='e-rotate-gif' />
                                Scroll horizontally for view more  information</p>
                        </div>

                    </div>
                    {
                        loader === true ?
                            <div className="h-100vh">
                                <CustomLoader />
                            </div>
                            :
                            userList.length !== 0 ?
                                <div className="min-height-80vh">
                                    <div className="col-12 position-relative" id="e-table-body">
                                        <DataTableContainer columns={USER_COLUMNS}
                                            data={userList}
                                            selectableRows={false}
                                            pagination={true}
                                            customStyles={TABLE_STYLE}
                                            onRowMouseEnter={_handleRowHover}
                                            defaultSortFieldId={1}
                                            onRowMouseLeave={_handleRowRemoveHover}
                                            paginationTotalRows={totalLength}
                                            paginationComponentOptions={paginationData}
                                            onChangeRowsPerPage={(e) => {
                                                setPageSize(e);
                                                setPageNumber(1);
                                            }}
                                            paginationPerPage={pageSize}
                                            rowClick={(row) => {
                                                _handleCardClick(row)
                                            }} />
                                        <div className={`d-flex justify-content-end align-items-center mt-3 position-absolute bottom-16px right-24 w-max-content`}>
                                            <Icon icon="pagination-previous" width="24px" height="24px"
                                                className={pageNumber === 1 ? 'pointer-events-none opacity-4 mx-3' : 'mx-3 cursor-pointer'}
                                                onClick={_previousPage} />
                                            <Icon icon="pagination-next" width="24px" height="24px"
                                                className={pageNumber === totalPages ? 'ms-1 pointer-events-none opacity-4' : 'ms-1 cursor-pointer'}
                                                onClick={_nextPage} />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="mb-5">
                                    <EmptyScreen title="Empty user data!" />
                                </div>
                    }
                    {
                        unlockModal === true ?
                            <UnlockSIPModal
                                handleOTPSubmission={_downloadUsers}
                                close={() => {
                                    setUnlockModal(false);
                                }} />
                            :
                            null
                    }
                </div>

            }

            
        </Fragment>
    )
});

export default AdminUserListing;