
/*
 *   File : request-list.js
 *   Author URI : https://evoqins.com
 *   Description : Page for list B2B KYC request 
 *   Integrations : null
 *   Version : v1.1
 */

/* import packages */
import React, { useEffect, useState, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";

/* import components */
import { NavigationHeader } from "../../Components/Header";
import { SectionTitle } from "../../Components/Title";
import { DataTableContainer } from "../../Components/FormElements";
import { GradientButton, LinkText, SecondaryButton } from "../../Components/Cta";
import { Icon } from "../../Components/Icon";
import { CustomLoader, EmptyScreen } from "../../Components/Other";
import { KYCRequestSuccess, CreateUserAccount } from "../../Components/Modal";
/* import styles */
import Colors from '../../Styles/color.module.scss';

/* import services */
import APIService from "../../Services/api-service";
import { _sortDate } from "../../Helper/helper-function";


export default React.memo(function KYCRequestList(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const ADMIN_PROFILE_DATA = useSelector(state => state.MT_ADMIN_STORE.ADMIN_PROFILE);

    const [loader, setLoader] = useState(false);
    const [requestData, setRequestData] = useState([]);
    const [paginationData, setPaginationData] = useState({});
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize, setPageSize] = useState(12);
    const [totalLength, setTotalLength] = useState(3);
    const [KYCLink, setKYCLink] = useState("");
    const [openLinkModal, setOpenLinkModal] = useState(false);
    const [showSignUpModal, setShowSignUpModal] = useState(false);

    const TABLE_COLUMNS = [

        {
            name: 'Created on',
            selector: row => row['created'],
            sortable: true,
            left: true,
            width: "150px",
            sortFunction: (a, b) => _sortDate(a.created, b.created),
            // maxWidth: "250px",
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0 mt-0 gap-12px color-outer-space">
                    {row['created']}
                </p>
        },
        {
            name: 'Name',
            selector: row => row['name'],
            sortable: false,
            left: true,
            minWidth: "200px",
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0 mt-0 gap-12px color-outer-space">
                    {row['name']}
                </p>,
        },
        {
            name: 'Email',
            selector: row => row['email'],
            sortable: true,
            left: true,
            minWidth: "300px",
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0 mt-0 gap-12px color-outer-space">
                    {row['email']}
                </p>
        },
        {
            name: 'Phone',
            selector: row => row['mobile'],
            sortable: false,
            left: true,
            minWidth: "150px",
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0 mt-0 color-outer-space">
                    {row['mobile']}
                </p>
        },
        {
            name: 'Branch name',
            selector: row => row['branch_name'],
            sortable: false,
            center: true,
            minWidth: "200px",
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0 mt-0 color-outer-space">
                    {row['branch_name']}
                </p>
        },
        {
            name: 'Branch code',
            selector: row => row['branch_code'],
            sortable: false,
            center: true,
            width: "170px",
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0 mt-0 color-outer-space">
                    {row['branch_code']}
                </p>
        },
        {
            name: 'RM name',
            selector: row => row['rm'],
            sortable: false,
            center: true,
            width: "170px",
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0 mt-0 color-outer-space">
                    {row['rm']}
                </p>
        },
        {
            name: 'RM code',
            selector: row => row['rm_code'],
            sortable: false,
            center: true,
            width: "170px",
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0 mt-0 color-outer-space">
                    {row['rm_code']}
                </p>
        },
        {
            name: '',
            sortable: false,
            right: true,
            maxWidth: "100%",
            cell: row =>
                <>

                    <div className={`e-fund-order-hover text-start`}>
                        <LinkText title="View link"
                            icon="arrow-right"
                            className="d-flex"
                            onClick={() => _handleRowClick(row.encrypted_link)} />
                    </div>
                </>

        }
    ];

    const TABLE_STYLE = {
        table: {
            style: {
                padding: "12px 16px 16px 16px",
                background: Colors.white,
            },
        },
        tableBody: {
            style: {
                margin: '0px',
                marginTop: "0px !important"
            }
        },
        headRow: {
            style: {
                background: 'transparent',
                borderRadius: "16px 16px 0px 0px",
                border: "0",
                borderBottom: `1px solid ${Colors.bright_gray} !important`,
                fontSize: "14px",
                fontFamily: "Poppins-Medium",
                lineHeight: "24px",
                color: Colors.outer_space
            }
        },
        rows: {
            style: {
                margin: "0",
                background: "transparent",
                border: `1px solid ${Colors.bright_gray} !important`,
                borderRadius: "16px",
                marginTop: "14px",
                fontSize: "16px",
                fontFamily: "Poppins-Regular",
                lineHeight: "24px",
                cursor: "pointer",
                '&:hover': {
                    boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
                },
            },
        },
        cells: {
            style: {
                '&:not(:last-child)': {
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    display: 'flex',
                },
                '&:first-child': {
                    padding: "24px 0px 24px 24px",
                }
            },
        },
        headCells: {
            style: {
                '&:not(:last-child)': {
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    display: 'flex',
                },
                '&:first-child': {
                    padding: "24px 0px 24px 24px",
                }
            },
        },
        pagination: {
            style: {
                borderTop: `none `,
                color: Colors.outer_space,
                fontSize: '16px',
                fontFamily: "Poppins-Regular",
            },
        }
    }

    useEffect(() => {
        _getRequestList();
    }, [pageNumber]);

    useEffect(() => {
        if (openLinkModal === true) {
            const modal = new Modal(document.getElementById("kyc-request-success"));
            modal.show();
        }
    }, [openLinkModal]);

    useEffect(() => {
        if (showSignUpModal === true) {
            const modal = new Modal(document.getElementById("create-user"));
            modal.show();
        }
    }, [showSignUpModal]);

    // handle table row click
    function _handleRowClick(link) {
        setKYCLink(link);
        setOpenLinkModal(true);
    }

    // navigate start KYC
    function _handleCreateKYC() {
        setShowSignUpModal(true);
    }

    function _closeSignUpModal() {
        setShowSignUpModal(false);
    }

    // handle new request button click
    function _createNewRequest() {
        navigate("/kyc-request/create-request")
    }

    // callback function link modal close
    function _callBackURLModalClose() {
        setKYCLink("");
        setOpenLinkModal(false);
    }

    // handler for page change
    function _nextPage() {
        setPageNumber(prevCount => prevCount + 1);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    // got to previous page
    function _previousPage() {
        setPageNumber(prevCount => prevCount - 1);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    // API - get kyc request data
    function _getRequestList() {
        setLoader(true);
        let url = 'user/list-ekyc-link';
        let payload = JSON.stringify({
            page_num: pageNumber,
            page_size: 20
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setRequestData(response.data.data);
                setPaginationData({ rowsPerPageText: `${response.data.page} | Total records: ${response.data.number_of_records}`, })
                let total_pages = Math.ceil(response.data.number_of_records / response.data.page_size);
                setTotalPages(total_pages);
            }
            setLoader(false);
        })
    }

    return (

        <Fragment>
            <NavigationHeader
                type={1}
                title="KYC request" />
            <div className="ps-4 pe-lg-5 pe-md-2 pe-2 padding-42px-top">
                <div className="row padding-32px-bottom">
                    <div className="col-lg-6 col-md-7 col-6">
                        <SectionTitle title="Manage B2B KYC request" />
                    </div>
                    <div className="col-lg-6 col-md-5 col-6 text-end">
                        <SecondaryButton label="Create user account"
                            className="py-1 me-2"
                            onPress={_handleCreateKYC} />
                        <GradientButton label="Create new request"
                            onPress={_createNewRequest} />
                    </div>
                </div>

                {/* Table */}
                <>
                    {
                        loader === true ?
                            <CustomLoader />
                            :
                            <>
                                {
                                    requestData.length !== 0 ?
                                        <div className="min-height-80vh">
                                            <div className="col-12 text-end mt-2">
                                                <p className="color-outer-space e-poppins-regular e-font-12 mb-0">
                                                    <img draggable={false} src={require('../../Assets/Images/Onboarding/scroll-gif.gif')} alt="scroll"
                                                        width='24px'
                                                        height='24px'
                                                        className='e-rotate-gif' />
                                                    Scroll horizontally for view more  information</p>
                                            </div>
                                            <div className="col-12 position-relative" id="e-table-body">
                                                <DataTableContainer columns={TABLE_COLUMNS}
                                                    data={requestData}
                                                    selectableRows={false}
                                                    pagination={true}
                                                    customStyles={TABLE_STYLE}
                                                    // onRowMouseEnter={_handleRowHover}
                                                    defaultSortFieldId={1}
                                                    // onRowMouseLeave={_handleRowRemoveHover}
                                                    paginationTotalRows={totalLength}
                                                    paginationComponentOptions={paginationData}
                                                    onChangeRowsPerPage={(e) => {
                                                        setPageSize(e);
                                                        setPageNumber(1);
                                                    }}
                                                    paginationPerPage={pageSize}
                                                    rowClick={(row) => {

                                                    }} />
                                                <div className={`d-flex justify-content-end align-items-center mt-3 position-absolute bottom-16px right-24 w-max-content`}>
                                                    <Icon icon="pagination-previous" width="24px" height="24px"
                                                        className={pageNumber === 1 ? 'pointer-events-none opacity-4 mx-3' : 'mx-3 cursor-pointer'}
                                                        onClick={_previousPage} />
                                                    <Icon icon="pagination-next" width="24px" height="24px"
                                                        className={pageNumber === totalPages ? 'ms-1 pointer-events-none opacity-4' : 'ms-1 cursor-pointer'}
                                                        onClick={_nextPage} />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="mb-5">
                                            <EmptyScreen title="Empty KYC request!" />
                                        </div>
                                }
                            </>
                    }
                </>

            </div>
            {
                openLinkModal === true &&
                <KYCRequestSuccess url={KYCLink}
                    newRequest={false}
                    close={_callBackURLModalClose} />
            }
            {
                showSignUpModal === true &&
                <CreateUserAccount close={_closeSignUpModal} />
            }

        </Fragment>
    )
});