
/*
 *   File : add-bank-header.js
 *   Author URI : https://evoqins.com
 *   Description : Modal header
 *   Integrations : null
 *   Version : v1.1
 */
import Icon from '../../Icon/icon';

import Colors from '../../../Styles/color.module.scss';

export default function AddBankHeader(props) {
    return (
        <div className="container gx-0">
            <div className="row">
                {
                    props.showNavButton === true && (
                        <div className="col-12 text-start">
                            <h4 className="color-black e-poppins-medium e-font-16 cursor-pointer w-max-content mt-1"
                                onClick={() => {
                                    if (props.kycFlow === false) {
                                        props.navigation();
                                    }
                                }}
                            >
                                {props.kycFlow === false &&
                                    <Icon
                                        icon="back-arrow"
                                        color={Colors.black}
                                        size={24}
                                        className={`me-3`}
                                    />
                                }
                                Link your bank account
                            </h4>

                        </div>
                    )
                }
                {
                    <div className="col-10 text-start mt-3">
                    <p className="e-poppins-regular e-font-14 color-outer-space">
                        Add bank account to transfer funds securely for investing in Mastertrust.
                    </p>
                </div>
                }
            </div>
        </div>
    );
};