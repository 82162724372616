
/*
 *   File : kyc-status.js
 *   Author URI : https://evoqins.com
 *   Description : Fetch data from digi locker.
 *   Integrations : null
 *   Version : v1.1
 */
import { useState, Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Cookies from 'js-cookie';

import { GradientButton } from "../../../Components/Cta";
import { RadioGroup } from "../../../Components/FormElements";
import { CustomLoader } from "../../../Components/Other";
import { _getKycProfile } from "../../../Helper/api";

import APIService from "../../../Services/api-service";

const ADDRESS_OPTIONS = [{
    id: 1,
    label: "Proceed with existing details"
},
{
    id: 2,
    label: "Update details using Digilocker"
}]

export default function KYCStatusPage(props) {

    const KIRA_INFO = useSelector(state => state.MT_ADMIN_STORE.KYC_DATA.kra_info);
    const location = useLocation();
    const [apiLoader, setApiLoader] = useState(false);
    const [kycCompleted, setKycCompleted] = useState(false);
    const [selected_kyc, setselected_kyc] = useState(ADDRESS_OPTIONS[0].id);
    const [kycCompleteData, setKycCompletedData] = useState({});
    const [loader, setLoader] = useState(false);
    const [isAddressUpdated, setIsAddressUpdated] = useState(false);

    useEffect(()=>{
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },[]);

    useEffect(() => {
        setKycCompletedData(KIRA_INFO);
        setKycCompleted(KIRA_INFO.kyc_compliant);
        setLoader(false)
    }, [])

    // API - initiate digilocker to get address
    function _getAddressFromAadhar() {
        setApiLoader(true);
        let url = 'kyc/digilocker/initiate';
        let payload = JSON.stringify({
            customer_id: location.state.user_id
        })
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                _initiateDigiLocker(response.data.id, response.data.customer_identifier, response.data.access_token.id);
                Cookies.set('digio_token', response.data.access_token.id);

            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
                setApiLoader(false);
            }
        })
        // props.updateProgress(4);
    }

    // open digilocker UI to enter aadhar number and get address
    async function _initiateDigiLocker(digiLockerId, mail, token_id) {
        // Check if window.Digio is loaded
        if (!window.Digio) {
            console.error("Digio is not available on window object.");
            toast.error("Digilocker integration not available. Please try again later.");
            return;
        }

        const Digio = await window.Digio;
        console.log("Digio loaded:", Digio); // Debug log to ensure Digio is present

        const options = {
            environment: process.env.REACT_APP_DIGILOCKER_ENVIRONMENT,
            callback: _digiLockerCallback,
            logo: process.env.REACT_APP_LOGO,
        };

        const digio = new Digio(options);

        digio.event_listener = function (data) {
            console.log("Handling funnel event:", data);
        };

        try {
            digio.init();
            digio.submit(digiLockerId, mail, token_id);
        } catch (err) {
            console.error("Error initializing Digio:", err);
            toast.error("Allow pop-ups and reload the page to fetch from digilocker.", {
                type: "error"
            });
        }
    }



    function _digiLockerCallback(e) {
        console.log("Digilocker callback event: ", e);
        let url = 'kyc/digilocker/callback';
        let payload = JSON.stringify({
            customer_id: location.state.user_id
        })
        APIService(true, url, payload).then(async (response) => {
            if (response.status_code === 200) {
                // props.updateProgress(4, response.data);
                toast.dismiss();
                toast.success('Aadhar card linked to your MT Wealth account', {
                    type: "success"
                });
                if (kycCompleted === true) {
                    setselected_kyc(ADDRESS_OPTIONS[0].id)
                    setIsAddressUpdated(true);
                    const kyc_data = await _getKycProfile(location.state.user_id)
                }
                // setKycCompleted(true);
                setKycCompletedData(response.data);
            }
            else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        })
    }

    // API - save address
    const _saveAddress = async () => {
        let url = 'kyc/save-address';
        let payload = JSON.stringify({
            customer_id: location.state.user_id
        });
        try {
            const response = await APIService(true, url, payload);
            if (response.status_code === 200) {
                const kyc_data = await _getKycProfile(location.state.user_id);
                props.updateProgress(4);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        } catch (error) {
            toast.dismiss();
            toast.error(error.message, {
                type: "error",
            });
        } finally {
            setApiLoader(false);
        }
    };


    const _handleRadioInput = (e) => {
        setselected_kyc(e)
    }

    const _handleProceed = () => {
        // props.updateProgress(4);
        setApiLoader(true);
        switch (selected_kyc) {
            case 1:
                _saveAddress();
                break;

            case 2: if (isAddressUpdated === true) {
                props.updateProgress(4);
            } else {
                _getAddressFromAadhar();
            }

                break;
        }
    }
    return (
        loader === true ?
            <CustomLoader />
            :
            kycCompleted === false ?
                <Fragment>
                    {
                        Object.keys(kycCompleteData).length && kycCompleteData.address.length !== 0 ?
                            <div className="row">
                                <div className="col-lg-7 col-md-7 col-12">
                                    <h2 className="color-black e-inter-bold e-font-24-sm-18 mb-2">
                                        You are not a KYC verified user
                                    </h2>
                                    <p className="color-outer-space e-poppins-regular e-font-sm-16-14 line-height-24px mb-4">
                                        Please verify the below details from Digilocker
                                    </p>
                                    <div className="border-radius-16px border-all border-primary-color bg-pale-cyan p-3">
                                        <p className="color-primary-color e-poppins-regular e-font-sm-16-14 mb-2">Aadhar number : {kycCompleteData.aadhaar}</p>
                                        <p className="color-primary-color e-poppins-regular e-font-sm-16-14 mb-2">Name : {kycCompleteData.name}</p>
                                        <p className="color-primary-color e-poppins-regular e-font-sm-16-14 mb-2">Address : {kycCompleteData.address}</p>
                                        <p className="color-primary-color e-poppins-regular e-font-sm-16-14 mb-0">Nationality : {kycCompleteData.nationality === true ? "Indian" : null}</p>
                                    </div>

                                    <GradientButton label="Proceed"
                                        loading={apiLoader}
                                        className="padding-12px-tb px-3 margin-40px-top-sm-16 mb-sm-0 mb-3 mob-w-100"
                                        handleClick={_saveAddress} />
                                </div>
                            </div>
                            :
                            <div className="row">
                                <div className="col-lg-7 col-md-7 col-12">
                                    <h2 className="color-black e-inter-bold e-font-24-sm-18">
                                        Fetch your identity using DigiLocker
                                    </h2>
                                    <p className="color-outer-space e-poppins-regular e-font-sm-16-14 mt-3">
                                        You are not KYC registered user. In order to invest you needs to registered with KRA.
                                        It may take few seconds.
                                    </p>

                                    {/* Card notes */}
                                    <div className="bg-beige border-radius-16px p-3">
                                        <h6 className="color-black e-poppins-medium e-font-14-sm-12">*Note</h6>
                                        <div className="d-flex color-outer-space e-poppins-regular e-font-14-sm-12 ms-2">
                                            <p>1.</p>
                                            <p className="line-height-20px margin-block-end-0">
                                                If you do not have a Digilocker account, you can still proceed with your Aadhaar and
                                                it will be created during the process.
                                            </p>
                                        </div>
                                        <div className="d-flex color-outer-space e-poppins-regular e-font-14-sm-12 margin-block-end-0 mt-2 ms-2">
                                            <p className="margin-block-end-0">2. </p>
                                            <p className="line-height-20px margin-block-end-0">
                                                We will be fetching both PAN and Aadhaar from Digilocker which may take a few seconds.
                                            </p>
                                        </div>
                                    </div>
                                    <GradientButton label="Continue with digilocker"
                                        loading={apiLoader}
                                        className="padding-14px-tb padding-16px-lr e-font-16 mt-sm-5 mt-3 mb-sm-0 mb-3 mob-w-100"
                                        onPress={() => {
                                            // props.updateProgress(4);
                                            _getAddressFromAadhar()
                                        }
                                        } />
                                </div>
                                <div className="col-4 offset-lg-1 mt-5 d-lg-block d-md-block d-none">
                                    <img src={require('../../../Assets/Images/kyc/fetch-kyc.png')}
                                        draggable={false}
                                        alt="" />
                                </div>
                            </div>
                    }
                </Fragment>
                :
                <Fragment>
                    <div className="row mb-sm-0 mb-3">
                        <div className="col-lg-7 col-md-7 col-12">
                            <h2 className="color-black e-inter-bold e-font-24-sm-18 mb-2">
                                You are already a registered KYC user
                            </h2>
                            <p className="color-outer-space e-poppins-regular e-font-sm-16-14 line-height-24px mb-sm-4 mb-3">
                                Please verify the below details from KYC Registration Agency (KRA).
                            </p>
                            <div className="border-radius-16px border-all border-primary-color bg-pale-cyan p-3">
                                <p className="color-primary-color e-poppins-regular e-font-sm-16-14 mb-2">Aadhar number : {kycCompleteData.aadhaar}</p>
                                <p className="color-primary-color e-poppins-regular e-font-sm-16-14 mb-2">Name : {kycCompleteData.name}</p>
                                <p className="color-primary-color e-poppins-regular e-font-sm-16-14 mb-2 word-break-all">Address : {kycCompleteData.address}</p>
                                <p className="color-primary-color e-poppins-regular e-font-sm-16-14 mb-0">Nationality : {kycCompleteData.nationality === true ? "Indian" : null}</p>
                            </div>

                            <RadioGroup data={ADDRESS_OPTIONS}
                                className={`gap-24px mt-3 mb-4`}
                                wrapperClass="w-unset d-flex align-items-center"
                                iconClassName="me-2"
                                type={1}
                                id={selected_kyc}
                                radioHandler={_handleRadioInput} />
                            {
                                isAddressUpdated === true &&
                                <div className='e-warning-info-wrapper d-flex align-items-center mt-3'>
                                    <p className='mb-0 e-poppins-regular line-height-20px e-font-11 mb-3'>
                                        You have successfully fetched updated address from Digilocker. Click Proceed to continue KYC registration.
                                    </p>
                                </div>
                            }

                            <GradientButton label="Proceed"
                                loading={apiLoader}
                                className="padding-12px-tb px-3 margin-40px-top-sm-16 mob-w-100"
                                onPress={_handleProceed} />


                        </div>
                    </div>
                </Fragment>

    )
}