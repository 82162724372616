/*
 *   File : personal-details.js
 *   Author URI : https://evoqins.com
 *   Description :   Component which allows the user to add personal details to their KYC verification
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import parse from 'html-react-parser';
import { Tooltip } from "react-tooltip";

import { CustomTextInput, RadioGroup } from "../../../Components/FormElements";
import { FilePreview } from "../../../Components/Cards";
import CustomFilePicker from "../../../Components/FormElements/file-upload";
import { CustomLoader } from "../../../Components/Other";
import { GradientButton } from "../../../Components/Cta";
import { Icon } from "../../../Components/Icon";
import { _convertToLabelIdArray, _isFileTypeAllowed } from "../../../Helper/helper-function";
import { _uploadDocumentToS3 } from "../../../Services/aws-service";
import APIService from "../../../Services/api-service";
import { _getKycProfile } from "../../../Helper/api";
import { FileViewer } from "../../../Components/Modal";

// import { SignaturePreviewModal } from "../../../Component/Modal";
import { Modal } from "bootstrap";

const RELATION = [
    { id: 162, label: "Father", image: null },
    { id: 163, label: "Spouse", image: null }
];

const GENDER = [
    { id: 51, label: "Male", image: null },
    { id: 52, label: "Female", image: null },
    { id: 53, label: "Transgender", image: null }
];

const MARITAL = [
    { id: 99, label: "Married", image: null },
    { id: 100, label: "Unmarried", image: null }
];

const OCCUPATION = [
    { id: 106, label: "Private Sector Service" },
    { id: 107, label: "Public Sector" },
    { id: 108, label: "Business" },
    { id: 109, label: "Professional" },
    { id: 110, label: "Agriculturist" },
    { id: 111, label: "Retired" },
    { id: 112, label: "Housewife" },
    { id: 113, label: "Student" },
    { id: 114, label: "Forex Dealer" },
    { id: 115, label: "Government Service" },
    { id: 116, label: "Others" }
];

const INCOME = [
    { id: 68, label: "BELOW 1 LAC", image: null },
    { id: 69, label: "1-5 LAC", image: null },
    { id: 70, label: "5-10 LAC", image: null },
    { id: 71, label: "10-25 LAC", image: null },
    { id: 72, label: ">25 LAC", image: null },
    { id: 73, label: "25 LAC - 1 CR", image: null },
    { id: 74, label: ">1 CR", image: null }
];

const PersonalDetailsPage = (props) => {

    const GENERAL_TYPES = useSelector(state => state.MT_ADMIN_STORE.KYC_GENERAL_FILTERS);
    const PERSONAL_DATA = useSelector(state => state.MT_ADMIN_STORE.KYC_DATA.additional_info);
    // const TOOL_TIP = useSelector(state => state.generalData.TOOL_TIP_DATA);
    const location = useLocation();

    const [relationData, setRelationData] = useState(RELATION);
    const [selectedRelation, setSelectedRelation] = useState(null);
    const [loader, setLoader] = useState(false);
    const [fatherName, setFatherName] = useState("");
    const [fatherNameError, setFatherNameError] = useState("");
    const [genderData, setGenderData] = useState(GENDER);
    const [selectedGender, setSelectedGender] = useState(null);
    const [maritalData, setMaritalData] = useState(MARITAL);
    const [selectedMaritalStatus, setSelectedMaritalStatus] = useState(null);
    const [occupationData, setOccupationData] = useState(OCCUPATION);
    const [selectedOccupation, setSelectedOccupation] = useState(null);
    const [annualIncomeData, setAnnualIncomeData] = useState(INCOME);
    const [selectedAnnualIncome, setSelectedAnnualIncome] = useState(null);
    const [showInfo, setShowInfo] = useState(false);

    const [file, setFile] = useState("");
    const [fileError, setFileError] = useState("");
    const [fileUrl, setFileUrl] = useState("");
    const [fileLoader, setFileLoader] = useState(false);
    const [fileSize, setFileSize] = useState("");
    const [filePreview, setFilePreview] = useState("");
    const [apiLoader, setApiLoader] = useState(false);
    const [openFileViewer, setOpenFileViewer] = useState(false);
    const [showDeletedRed, setShowDeletedRed] = useState(false);

    useEffect(()=>{
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },[]);

    useEffect(() => {
        if (Object.keys(GENERAL_TYPES).length !== 0) {

            if (PERSONAL_DATA.father_name !== null) {
                setFatherName(PERSONAL_DATA.father_name);
            }
            let gender = _convertToLabelIdArray(GENERAL_TYPES.gender);
            setGenderData(gender);
            if (PERSONAL_DATA.gender !== null) {
                setSelectedGender(PERSONAL_DATA.gender);
            }
            else {
                setSelectedGender(gender[0].id);
            }

            let annual_income = _convertToLabelIdArray(GENERAL_TYPES.income_slab);
            setAnnualIncomeData(annual_income);
            if (PERSONAL_DATA.income !== null) {
                setSelectedAnnualIncome(PERSONAL_DATA.income)
            }
            else {
                setSelectedAnnualIncome(annual_income[0].id)
            }

            let marital_status = _convertToLabelIdArray(GENERAL_TYPES.marital_status);
            setMaritalData(marital_status);
            if (PERSONAL_DATA.marital_status !== null) {
                setSelectedMaritalStatus(PERSONAL_DATA.marital_status);
            }
            else {
                setSelectedMaritalStatus(marital_status[0].id);
            }

            let occupation = _convertToLabelIdArray(GENERAL_TYPES.occupation)
            setOccupationData(occupation);
            if (PERSONAL_DATA.occupation !== null) {
                setSelectedOccupation(PERSONAL_DATA.occupation);
            }
            else {
                setSelectedOccupation(occupation[0].id);
            }

            let relation = _convertToLabelIdArray(GENERAL_TYPES.kyc_relation)
            setRelationData(relation);
            setSelectedRelation(relation[0].id);

            if (PERSONAL_DATA.signature_status !== null) {
                const file_name = PERSONAL_DATA.signature_status.substring(PERSONAL_DATA.signature_status.lastIndexOf('/') + 1, PERSONAL_DATA.signature_status.indexOf('?'));
                setFile(file_name)
                setFileUrl(PERSONAL_DATA.signature_status);
                setFileSize(PERSONAL_DATA.file_size);
            }
            setLoader(false);
        }
    }, [GENERAL_TYPES, PERSONAL_DATA])

    useEffect(() => {
        if (openFileViewer === true) {
            const modal = new Modal(document.getElementById("file-viewer"));
            modal.show();
        }
    }, [openFileViewer]);

    function _callbackFileViewerModalClose() {
        setFilePreview("");
        setOpenFileViewer(false);
    }

    function _handleDelete () {
        setShowDeletedRed(!showDeletedRed);
    }

    function clearFile () {
        setFile("");
        setFileUrl("");
        setFileSize("");
    }

    const _handleInputFields = (type, data) => {
        switch (type) {
            case 1:
                setSelectedRelation(data);
                break;
            case 2:
                setFatherName(data);
                setFatherNameError("");
                break;
            case 3:
                setSelectedGender(data);
                break;
            case 4:
                setSelectedMaritalStatus(data);
                break;
            case 5:
                setSelectedOccupation(data);
                break;
            case 6:
                setSelectedAnnualIncome(data);
                break;
        }
    }

    const _handleFileUpload = (value, file) => {
        //eslint-disable-next-line
        var file_formatted = value.replace(/^.*[\\\/]/, '').split('/');
        //eslint-disable-next-line
        file_formatted = file_formatted[0].replace(/[\(\)\s]/g, '_');
        console.log("File", file);
        console.log("Value", value)
        console.log("File formatted", file_formatted);
        let type = file.type;
        if (_isFileTypeAllowed(type)) {
            // checking if file size is greater than 5mb
            const file_size = 5 * 1024 * 1024;
            if (file.size < file_size) {
                _fileUpload(file_formatted, file);
                setFileSize(file_size);
            } else {
                setFileError("File size should be less than 5mb");
            }
        } else {
            toast.dismiss();
            toast.error("File format should be in png, jpeg, pdf", {
                type: "error",
            });
        }
    }

    function _isFileTypeAllowed(fileType) {
        const valid_mime_type = [
            "image/png", // .png
            "image/jpeg", // .jpg, .jpeg
            "application/pdf", // .pdf
        ];
        return valid_mime_type.includes(fileType);
    }

    const _validateForm = () => {
        if (fatherName.length === 0) {
            setFatherNameError("Enter father name");
            _scrollToTop();
        } else if (fileUrl.length === 0) {
            setFileError("File is required");
        } else {
            setApiLoader(true);
            _savePersonalData();
            // props.updateProgress(5);
        }
    };

    const _scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const _handlePreviewImage = (url, type) => {
        type = type === true ? 1 : 2;
        setFilePreview({ url, type });
        setOpenFileViewer(true);
    }

    const _extractFileNameFromUrl = (url) => {
        const match = url.match(/\/([^\/?#]+)[^\/]*$/);
        return match ? match[1] : null;
    };


    //API :: file upload api
    const _fileUpload = (key, file) => {
        setFileError("");
        setFileLoader(true);
        let url = "general/file-upload";
        let body = {
            "key": `support/${key}`
        }

        APIService(true, url, body).then((response) => {
            if (response.status_code === 200) {
                setFileLoader(true);

                // s3 helper
                _uploadDocumentToS3(response.data, key, file).then((result) => {
                    setFileUrl(response.data.view_info); // file got uploaded successfully

                    console.log("KEY", key)
                    setFile(key);
                    setFileLoader(false);
                }).catch(error => {
                    toast.dismiss();
                    toast.error(error, {
                        type: "error",
                    });
                    setFileLoader(false);
                });

            } else {
                setFileLoader(false);
                toast.dismiss();
                toast.error(response, {
                    type: "error",
                });
            }
        })
    }

    async function _savePersonalData() {
        let url = 'kyc/save-personal';
        let payload = JSON.stringify({
            relation_id: selectedRelation,
            father_name: fatherName,
            gender_id: selectedGender,
            marital_status_id: selectedMaritalStatus,
            occupation_id: selectedOccupation,
            income_id: selectedAnnualIncome,
            sign_proof: fileUrl,
            customer_id: location.state.user_id
        });

        try {
            const response = await APIService(true, url, payload);

            if (response.status_code === 200) {
                const kyc_data = await _getKycProfile(location.state.user_id);
                props.updateProgress(5);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        } catch (error) {
            toast.dismiss();
            toast.error(error.message, {
                type: "error",
            });
        }
        finally {
            setApiLoader(false);
        }
    }

    const FilePreview = (props) => {
        return (
            <div className="d-flex w-90 align-items-center justify-content-between border-radius-8px border-all-dashed bg-pale-baby-blue px-3 padding-6-5px-tb cursor-pointer border-primary-color ">
                <div className="d-flex align-items-center">
                    {
                        props.pdf !== true && (
                            <img draggable={false} src={props.image}
                                alt="preview"
                                width={24}
                                height={24}
                                className="me-2 object-fit-contain" />
                        )
                    }

                    <div>
                        {
                            props.image !== null && (
                                <p className="border-radius-8px d-block bg-pale-baby-blue color-jett-black e-font-14 e-poppins-regular line-height-22px  mb-0 overflow-wrap-anywhere">
                                    {_extractFileNameFromUrl(props.image)}
                                </p>
                            )
                        }

                        <p className="color-outer-space d-block e-font-8 mb-0">
                            File size: {props.fileSize}
                        </p>
                    </div>
                </div>
                <span>
                    <Icon icon={"eye"} alt="Preview"
                        size={24}
                        className="margin-6px-left cursor-pointer"
                        onClick={() => _handlePreviewImage(props.image, props.pdf)} />
                </span>
            </div>
        )
    }

    return (
        loader === true ?
            <CustomLoader />
            :
            <div className="pb-sm-0 pb-3">
                <p className="color-black e-poppins e-poppins-bold e-font-24-sm-16 mb-2">Share a bit about yourself</p>
                <p className="color-outer-space e-poppins-regular e-font-sm-16-14 line-height-24px mb-0">
                    Share a few more personal information to know about your investment preference.
                </p>

                {/* Relation */}
                <p className="color-black e-poppins-medium e-font-18-sm-16 margin-32px-top">Relation</p>
                <RadioGroup data={relationData}
                    className={`gap-16px mt-3`}
                    type={2}
                    id={selectedRelation}
                    radioHandler={(id) => _handleInputFields(1, id)} />

                {/* Father input field */}
                <div className="row margin-26px-top">
                    <div className="col-lg-4 col-md-6 col-12">
                        <CustomTextInput label="Father name"
                            postfix="*"
                            value={fatherName}
                            error={fatherNameError}
                            valueChange={(data) => _handleInputFields(2, data)} />
                    </div>
                </div>

                {/* Gender */}
                <p className="color-black e-poppins-medium e-font-18-sm-16 mt-3">Gender</p>
                <RadioGroup data={genderData}
                    className={`gap-16px mt-3`}
                    type={2}
                    id={selectedGender}
                    radioHandler={(id) => _handleInputFields(3, id)} />

                {/* Marital status */}
                <p className="color-black e-poppins-medium e-font-18-sm-16 mt-4">Marital status</p>
                <RadioGroup data={maritalData}
                    className={`gap-16px mt-3`}
                    type={2}
                    id={selectedMaritalStatus}
                    radioHandler={(id) => _handleInputFields(4, id)} />

                {/* Occupation */}
                <p className="color-black e-poppins-medium e-font-18-sm-16 mt-4">Your occupation</p>
                <RadioGroup data={occupationData}
                    className={`gap-16px mt-3`}
                    type={3}
                    id={selectedOccupation}
                    radioHandler={(id) => _handleInputFields(5, id)} />

                {/* Annual income */}
                <p className="color-black e-poppins-medium e-font-18-sm-16 mt-4">Your annual income</p>
                <RadioGroup data={annualIncomeData}
                    className={`gap-16px mt-3`}
                    type={2}
                    id={selectedAnnualIncome}
                    radioHandler={(id) => _handleInputFields(6, id)} />

                {/* Digital signature */}
                <p className="color-black e-poppins-medium e-font-18-sm-16 mt-4">Upload digital signature</p>

                <p className="color-outer-space e-poppins-regular e-font-sm-16-14 line-height-24px border-radius-8px bg-beige w-fit-content p-3 mb-4">
                    *Sign on a blank white paper using blue ink, take a clear photo of it and upload
                </p>
                <div className="row d-flex align-items-center">
                    <div className="col-lg-4 col-md-6 col-10">
                        {
                            file.length === 0 ?
                                <CustomFilePicker accept=".png, .pdf, .jpeg "
                                    className="e-select-file"
                                    error={fileError}
                                    loading={fileLoader}
                                    onSelectFile={(path, file) => _handleFileUpload(path, file)} />
                                :
                                <div className="w-100 d-flex align-items-center">
                                    <FilePreview image={fileUrl}
                                        fileSize={fileSize} />
                                    <Icon icon={showDeletedRed ? "delete-red" : "delete"} alt="delete"
                                        size={24}
                                        className="margin-6px-left cursor-pointer "
                                        onClick={clearFile}
                                        onMouseEnter={_handleDelete}
                                        onMouseLeave={_handleDelete} />
                                </div>

                        }
                    </div>
                    {/* <div className="col-lg-4 col-md-6 col-2">
                        {
                            Object.keys(TOOL_TIP).length !== 0 &&
                            <div className="position-relative">
                                <Icon icon="info"
                                    size={24}
                                    className="cursor-pointer"
                                    data-tooltip-id="sign-tooltip" />
                                <Tooltip id="sign-tooltip"
                                    arrowColor='transparent'
                                    effect="float"
                                    place="bottom"
                                    className='e-tooltip-general'>
                                    {parse(TOOL_TIP?.kyc_sign_upload)}
                                </Tooltip>
                            </div>
                        }

                    </div> */}
                </div>

                <GradientButton label="Continue"
                    loading={apiLoader}
                    className="px-3 padding-12px-tb e-font-16 margin-40px-top mob-w-100"
                    onPress={_validateForm} />
                {
                    openFileViewer === true &&
                    <FileViewer data={filePreview}
                        close={_callbackFileViewerModalClose} />
                }

            </div>
    )
}

export default PersonalDetailsPage