/*
 *   File : text-input.js
 *   Author URI : www.evoqins.com
 *   Description : Input
 *   Integrations : null
 *   Version : v1.0
 *   Created : 
 */

/* import package */
import React, { Fragment, useEffect, useState, useRef } from "react";

/*import components */
import Icon from '../Icon/icon';

/*import styles*/
import style from "../../Styles/Components/input.module.scss";

const CustomTextInput = React.memo(function CustomTextInput(props) {
    //eslint-disable-next-line
    const [isInputFocused, setInputFocus] = useState(false);
    const inputRef = useRef(null);
    const [value, setValue] = useState(props.value);
    const [showPassword, setShowPassword] = useState(true);
    const [inputReadOnly, setInputReadOnly] = useState(false);

    // const INVALID_CHARS = [
    //     "-",
    //     "+",
    //     "e",
    //     ","
    // ];

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        if (props.isAuthInput) {
            setInputReadOnly(true);
            setTimeout(() => {
                setInputReadOnly(false);
            }, 500);
        }
    }, [props.isAuthInput]);

    // focus input
    function _focusInputAtEnd() {
        if (inputRef.current) {
            setTimeout(() => {
                inputRef.current.focus();
                inputRef.current.setSelectionRange(
                    inputRef.current.value.length,
                    inputRef.current.value.length
                );
            }, 0);
        }
    }

    const _handleChange = (event) => {
        let input_value = event.target.value;
        if (props.uppercase === true) {
            input_value = input_value.toUpperCase(); 
        }
        console.log('input', input_value);
        if (props.max && input_value.length > props.max) {
            props.valueChange(input_value.substring(0, props.max));
            setValue(input_value.substring(0, props.max));
        } else {
            props.valueChange(input_value);
            setValue(input_value);
        }
    }

    /* ---- func to be implemented on press of enter ----*/
    const _keyPress = (e) => {
        if (props.type === "number") {
            const key_code = e.keyCode || e.which;
            const is_ctrl_pressed = e.ctrlKey || e.metaKey; // Check if Ctrl key is pressed
            const allowed_key_codes = [9, 8, ...Array.from({ length: 10 }, (_, i) => 48 + i), ...Array.from({ length: 10 }, (_, i) => 96 + i), ...Array.from({ length: 4 }, (_, i) => 37 + i), 190];

            if (key_code === 46 || (is_ctrl_pressed && [65, 67, 88, 86].includes(key_code))) {
                // Ctrl+A, Ctrl+C, Ctrl+X, or Ctrl+V
                return null;
            }

            if (!allowed_key_codes.includes(key_code)) {
                e.preventDefault();
            }
            // Disable arrow up and down keys
            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                e.preventDefault();
            }
        }
    };

    const _handleFocus = () => {
        setInputFocus(true);
    };

    const _handleBlur = () => {
        // setInputFocus(false);
    };

    return (
        <Fragment>

            <div className={
                props.error && props.error.length !== 0 ?
                    `${props.className} ${style.e_input_wrapper} ${style.e_error_input} position-relative w-100` :
                    `${props.className} ${style.e_input_wrapper} position-relative w-100`}
                data-disabled={props.disabled}>
                {/* Input box */}
                <input
                    value={value}
                    id={props.id ? props.id : props.label}
                    name={ props.name? props.name: props.label}
                    type={props.type ? props.type : 'text'}
                    className={`${style.e_input} ${props.inputClass} color-charleston-green e-poppins-regular e-font-16 w-100 no-outline`}
                    style={{ paddingRight: props.password_icon === true && "48px" }}
                    onChange={_handleChange}
                    placeholder={props.placeholder}
                    onFocus={_handleFocus}
                    onBlur={_handleBlur}
                    onKeyDown={_keyPress}
                    onWheel={() => document.activeElement.blur()}
                    autoComplete="off"
                    required
                    readOnly={inputReadOnly}
                    ref={inputRef}
                />
                {/* label */}
                {props.label && <label className={`${props.labelClass} position-absolute left-15px e-poppins-regular e-font-14 cursor-text p-0`}
                    htmlFor={props.id ? props.id : props.label}>
                    {props.label}
                    <span className='color-red'>
                        {props.postfix}
                    </span>
                </label>}
                {/* Error text */}
                {
                    props.hide_error_text ?
                        null
                        :
                        props.error &&
                        <div className='position-absolute d-flex align-items-center mt-1 '>
                            <Icon icon="info-circle"
                                size={16}
                                className="me-1" />
                            <p className='color-red mb-0 e-font-12 my-0 '>
                                {props.error}
                            </p>
                        </div>
                }
                {
                    value ?
                        <span className={style.e_suffix}>{props.suffix}</span>
                        :
                        null
                }
                {
                    props.password_icon ?
                        <span className={style.e_password_icon}>
                            <Icon icon={showPassword ? "eye" : 'eye-slash'}
                                size={24}
                                className='cursor-pointer'
                                onClick={() => {
                                    props.showPassword();
                                    setShowPassword(!showPassword)
                                    _focusInputAtEnd()
                                }} />
                        </span>
                        :
                        null
                }
            </div>
        </Fragment>
    );
});

CustomTextInput.defaultProps = {
    onChange: () => { },
    id: null,
    value: "",
    error: "",
    is_number: false,
    placeholder: "",
    className: "",
    title: "",
    labelClass: 'bg-white',
    disabled: false,
    isAuthInput: false
}

export default CustomTextInput;