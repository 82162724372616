/*
 *   File : add-bank.js
 *   Author URI : https://evoqins.com
 *   Description : Modal for adding bank
 *   Integrations : null
 *   Version : v1.1
 */

import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { GradientButton, SecondaryButton } from "../Cta";
import { Icon } from "../Icon";
import { Toggle, CustomTextInput, RadioGroup } from "../FormElements";

import { AddBankSuccess, AddBankUPIVerification, AddBankHeader } from "./helper";
import APIService from "../../Services/api-service";

import style from '../../Styles/Components/add-bank.module.scss';
import { useSelector } from "react-redux";

const IFSC_REGEX = /^[A-Z]{4}0[A-Z0-9]{6}$/;

export default function AddBankModal(props) {

    const BANK_DATA = useSelector(state => state.MT_ADMIN_STORE.KYC_DATA?.bank_details);
    const GENERAL_DATA = useSelector(state => state.MT_ADMIN_STORE.GENERAL_FILTERS);
    const location = useLocation();
    const [verifyUsingUPI, setVerifyUsingUPI] = useState(true); // decider for UPI or bank verification
    const [makeAsPrimaryBank, setMakeAsPrimaryBank] = useState(true); // set as primary bank toggle state
    const [accountLinkingType, setAccountLinkingType] = useState(0); // verification type 0 => initial screen, 1=> UPI, 2 manual
    const [accountHolderName, setAccountHolderName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [confirmAcNumber, setConfirmAcNumber] = useState('');
    const [ifscCode, setIfscCode] = useState('');
    const [selectedAccountType, setSelectedAccountType] = useState(1);
    const [accountType, setAccountType] = useState([]);
    const [verificationLoader, setVerificationLoader] = useState(false); // decider shows verification loader
    const [verificationSuccess, setVerificationSuccess] = useState(false); // decider shows verification success screen
    const [showHeader, setShowHeader] = useState(true); //decider render header
    const [upiResponse, setUpiResponse] = useState(null);
    const [apiLoader, setApiLoader] = useState(false);

    const [accountHolderNameError, setAccountHolderNameError] = useState(null);
    const [accountNumberError, setAccountNumberError] = useState(null);
    const [confirmAcNumberError, setConfirmAcNumberError] = useState(null);
    const [ifscCodeError, setIfscCodeError] = useState(null);
    const [upiStatus, setUPIstatus] = useState(true);

    // Modal close listener
    useEffect(() => {
        var my_modal = document.getElementById("add-bank");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, []);

    useEffect(()=>{
        setVerifyUsingUPI(props.verifyUsingUPI)
    },[]);

    // Get general data (to get account type)
    useEffect(() => {
        let data = GENERAL_DATA.bank_account_type.map((item) => {
            return ({ id: item.id, label: item.name })
        });
        if (props.kycFlow === true) {
            if (BANK_DATA.account_type_id !== null) {
                setSelectedAccountType(BANK_DATA.account_type_id);
            }
            else {
                setSelectedAccountType(data[0].id);
            }
        }
        else {
            setSelectedAccountType(data[0].id);
        }
        setAccountType(data);

        if (props.kycFlow === true) {
            if (props.verifyUsingUPI === true) {
                setAccountLinkingType(1)
                setUpiResponse(props.upiResponse)
            }
            else {
                if (BANK_DATA.account_number !== null) {
                    setAccountNumber(BANK_DATA.account_number);
                    setConfirmAcNumber(BANK_DATA.account_number);
                }
                if (BANK_DATA.account_type_id !== null) {
                    setSelectedAccountType(BANK_DATA.account_type_id);
                }
                if (BANK_DATA.ifsc_code !== null) {
                    setIfscCode(BANK_DATA.ifsc_code);
                }
                if (BANK_DATA.account_holder_name !== null) {
                    setAccountHolderName(BANK_DATA.account_holder_name);
                }
                setAccountLinkingType(2)
            }
            setVerifyUsingUPI(props.verifyUsingUPI)
        }
    }, []);



    // API to manually add bank account 
    async function _addBankAccount() {
        let valid_form = await _validateForm();
        if (valid_form === false) {
            return
        }
        setApiLoader(true);
        let url =  'kyc/add-bank';
        let payload = JSON.stringify({
            account_holder_name: accountHolderName,
            account_number: accountNumber,
            account_type_id: selectedAccountType,
            ifsc_code: ifscCode,
            is_default: props.kycFlow,
            primary: makeAsPrimaryBank,
            is_manual: !verifyUsingUPI,
            customer_id: location.state.user_id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                if (verifyUsingUPI === false) {
                    setVerificationSuccess(true);
                    setVerificationLoader(true);
                    setTimeout(() => { setVerificationLoader(false) }, 2000);
                } else {
                    setUpiResponse(response.data);
                    setAccountLinkingType(1);
                }
                setShowHeader(false);
            } else {
                if (verifyUsingUPI === false) {
                    setVerificationLoader(true);
                    setUPIstatus(false);
                    setVerificationSuccess(true);
                    setTimeout(() => {
                        setVerificationLoader(false);
                    }, 2000)
                }

                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        })
    };

    // Form validation add bank
    function _validateForm() {

        if (verifyUsingUPI === true) {
            return true;
        }
        let valid = true;
        if (accountHolderName.trim() === '') {
            valid = false;
            setAccountHolderNameError('Account holder name is required');
        } else if (/\d/.test(accountHolderName) || /[^A-Za-z\s]/.test(accountHolderName)) {
            valid = false;
            setAccountHolderNameError('Account holder name should not contain numbers, special characters, or dots');
        }
        if (accountNumber === '') {
            valid = false;
            setAccountNumberError('Account number required');
        } else if (accountNumber.length < 9 || accountNumber.length > 18) {
            valid = false;
            setAccountNumberError('Invalid account number');
        }
        if (confirmAcNumber === '') {
            valid = false;

            setConfirmAcNumberError('Confirm account number required');
        } else if (confirmAcNumber !== accountNumber) {
            valid = false;
            setConfirmAcNumberError('Account numbers must match');
        }
        if (ifscCode === '') {
            valid = false;
            setIfscCodeError('IFSC code required');
        } else if (!IFSC_REGEX.test(ifscCode)) {
            valid = false;
            setIfscCodeError('Invalid IFSC code');
        }
        return valid;
    }

    // Navigating from initial screen to respective linking screen
    function _handleVerifyButton() {
        if (verifyUsingUPI === true) {
            _addBankAccount();
        } else {
            setAccountLinkingType(2);
        }
    }

    // upi verification call back handle
    function _verifyBankAccount(status) {
        setVerificationSuccess(true);
        setUPIstatus(status);
        // show success gif only upi simulation success
        if (status === true) {
            setVerificationLoader(true);
            setTimeout(() => { setVerificationLoader(false) }, 2000)
        }
        setShowHeader(false);

    }

    // Back arrow click showing landing screen
    function _screenNavigation() {
        if (accountLinkingType !== 0) {
            setAccountLinkingType(0);
            setShowHeader(true);
        }
    }

    // Handle form input changes
    function _handleInputChange(value, type) {
        switch (type) {
            case 1: setAccountHolderName(value);
                setAccountHolderNameError(null);
                break;
            case 2: setAccountNumber(value);
                setAccountNumberError(null);
                break;
            case 3: setConfirmAcNumber(value);
                setConfirmAcNumberError(null);
                break;
            case 4: setIfscCode(value);
                setIfscCodeError(null);
            default: return;
        }
    }

    // Toggle handler for make as default bank switch
    function _toggleDefaultBankHandler() {
        if (props.numberOfBank !== 0) {
            setMakeAsPrimaryBank(!makeAsPrimaryBank);
        }
    }

    // component for Account linking type card
    function LinkBankTypeCard(props) {

        const { title, desc, upi, recommended } = props;
        return (
            <div className={`${props.className}`}
                onClick={() => props.handleClick()}>

                {/* Check icon */}
                <Icon icon={props.icon}
                    size={22}
                    className={`position-absolute cursor-pointer ${style.radio_icon} right-10px`} />

                <div className="row">
                    <div className="col-12 d-flex">
                        {/* Card title */}
                        <h4 className="color-black e-poppins-semi-bold e-font-16 margin-block-end-0 line-height-normal me-2">
                            {title}
                        </h4>
                        {/* UPI icon */}
                        <img src={props.image}
                            alt=""
                            className={`${style.e_upi_icon} me-2`} />
                        {/* Recommended badge */}
                        {
                            recommended === true && (
                                <span className={`border-radius-8px bg-bubble-light color-primary-color e-poppins-regular e-font-10 outline-1px-solid h-max-content padding-2px-tb padding-4px-lr`}>
                                    Recommended
                                </span>
                            )
                        }
                    </div>
                    {/* description */}
                    <div className="col-12 text-start">
                        <p className="e-inter-regular e-font-14 color-outer-space margin-block-end-0 mt-2">{desc}</p>
                    </div>
                </div>
            </div>
        )

    };


    return (
        <div className={`modal fade ${style.e_add_bank_modal}`}
            id="add-bank"
            tabIndex="-1"
            aria-labelledby="add-bank"
            aria-hidden="true"
            data-bs-backdrop="static" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-white position-relative text-center d-flex align-items-center p-4 ${style.e_content} `}>

                    {/* Close button shows every time unless api loading */}
                    {
                        verificationLoader === false && (
                            <Icon icon="close"
                                size={24}
                                data-bs-dismiss="modal"
                                className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`} />
                        )
                    }

                    {/* screen navigation link and title shows in initial screen and manual bank adding screen */}
                    {
                        showHeader === true ? (
                            <AddBankHeader navigation={_screenNavigation}
                                kycFlow={props.kycFlow}
                                verifyUsingUPI={props.verifyUsingUPI}
                                showNavButton={accountLinkingType === 0 ? false : true} />
                        ) : null
                    }

                    {
                        // Show verification success screen
                        verificationSuccess === true ? (
                            <AddBankSuccess loader={verificationLoader}
                                kycFlow={props.kycFlow}
                                updateBank={props.updateBank}
                                verificationType={accountLinkingType}
                                status={upiStatus} />
                        ) :
                            // Landing page | initial screen
                            accountLinkingType === 0 ? (
                                <div className={`container gx-0 ${style.e_scroll_container}`}>
                                    <div className="row mt-3">

                                        {/* Verify using UPI card */}
                                        <div className="col-12">
                                            <LinkBankTypeCard title="Verify with"
                                                desc="We’ll debit ₹1 from your account to verify the details. we’ll refund this once the verification is complete."
                                                upi={true}
                                                recommended={true}
                                                className={`${verifyUsingUPI === true ? 'border-pearl-aqua bg-frosty-cyan' : 'border-bright-gray'} p-3 border-all border-radius-16px position-relative cursor-pointer `}
                                                icon={verifyUsingUPI === true ? "radio-selected" : "radio-unselected"}
                                                image={require('../../Assets/Images/Profile/upi_logo.svg').default}
                                                handleClick={() => setVerifyUsingUPI(true)} />
                                        </div>

                                        {/* Manual bank verification card */}
                                        <div className="col-12">
                                            <LinkBankTypeCard title="Add details manually"
                                                desc="Add your account number, IFSC code manually."
                                                upi={false}
                                                recommended={false}
                                                className={`${verifyUsingUPI === false ? 'border-pearl-aqua bg-frosty-cyan' : 'border-bright-gray'} p-3 border-all border-radius-16px position-relative cursor-pointer mt-3`}
                                                icon={verifyUsingUPI === false ? "radio-selected" : "radio-unselected"}
                                                handleClick={() => setVerifyUsingUPI(false)} />
                                        </div>
                                    </div>
                                    {/* Footer section */}
                                    <div className="row">
                                        <div className="col-12 text-start mt-3">
                                            <p className="e-poppins-regular e-font-14 color-outer-space margin-block-end-0">
                                                *Use your primary account which matches with the name on your PAN card
                                            </p>
                                        </div>

                                        <div className={`col-12 d-flex mt-2`}
                                            data-disabled={props.numberOfBank === 0 ? true : false}>
                                            <p className="color-black e-poppins-medium e-fon-16 margin-block-end-0">
                                                Make this as your primary bank account
                                            </p>
                                            <Toggle className={`mt-2 ms-5 `}
                                                check={!makeAsPrimaryBank}
                                                onChange={_toggleDefaultBankHandler} />
                                        </div>

                                        <div className="col-12 text-start">
                                            <p className="color-black e-poppins-regular e-font-12">
                                                At a time only one bank be assigned as primary account.
                                            </p>
                                        </div>

                                        <div className="col-12 mt-3">
                                            <SecondaryButton label="Cancel"
                                                cancel="modal" />
                                            <GradientButton label={verifyUsingUPI === true ? "Verify with UPI" : "Verify manually"}
                                                className={`padding-12px-tb ${style.e_verify_btn} ms-3`}
                                                loading={apiLoader}
                                                onPress={_handleVerifyButton} />
                                        </div>
                                    </div>
                                </div>
                            )
                                // Verify using UPI
                                : accountLinkingType === 1 ? (
                                    <AddBankUPIVerification navigation={_screenNavigation}
                                        data={upiResponse}
                                        kycFlow={props.kycFlow}
                                        handleUPIVerificationSuccess={_verifyBankAccount} />
                                ) :
                                    // Verify manually
                                    (
                                        <div className={`container gx-0 ${style.e_scroll_container}`}>
                                            <div className="row">

                                                <div className="col-lg-6 col-md-6 col-12 mt-3">
                                                    <CustomTextInput label="Account holder name"
                                                        postfix="*"
                                                        value={accountHolderName}
                                                        error={accountHolderNameError}
                                                        valueChange={(e) => _handleInputChange(e, 1)} />
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-12 mt-3">
                                                    <CustomTextInput label="Account number"
                                                        postfix="*"
                                                        type="number"
                                                        maxLength={18}
                                                        value={accountNumber}
                                                        error={accountNumberError}
                                                        valueChange={(e) => _handleInputChange(e, 2)} />
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-12 mt-4 pt-3">
                                                    <CustomTextInput label="Confirm account number"
                                                        postfix="*"
                                                        type="number"
                                                        maxLength={18}
                                                        value={confirmAcNumber}
                                                        error={confirmAcNumberError}
                                                        valueChange={(e) => _handleInputChange(e, 3)} />
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-12 mt-4 pt-3">
                                                    <CustomTextInput label="IFSC code"
                                                        postfix="*"
                                                        pan={true}
                                                        uppercase={true}
                                                        value={ifscCode}
                                                        maxLength={11}
                                                        error={ifscCodeError}
                                                        valueChange={(e) => _handleInputChange(e, 4)} />
                                                </div>

                                                <div className=" col-12 text-start mt-3">
                                                    <h6 className="color-black e-poppins-regular e-font-16 mt-2">
                                                        Select bank account type
                                                    </h6>
                                                    <RadioGroup data={accountType}
                                                        className="gap-24px mt-3"
                                                        type={2}
                                                        id={selectedAccountType}
                                                        radioHandler={(e) => setSelectedAccountType(e)} />
                                                </div>

                                                <div className="col-12 d-flex mt-4"
                                                    data-disabled={props.numberOfBank === 0 ? true : false}>
                                                    <p className="color-black e-poppins-medium e-fon-16 margin-block-end-0">
                                                        Make this as your primary bank account
                                                    </p>
                                                    <Toggle className={`mt-2 ms-5`}
                                                        check={!makeAsPrimaryBank}
                                                        onChange={() => setMakeAsPrimaryBank(!makeAsPrimaryBank)} />
                                                </div>

                                                <div className="col-12 text-start">
                                                    <p className="color-black e-poppins-regular e-font-12">
                                                        At a time only one bank be assigned as primary account.
                                                    </p>
                                                </div>

                                                <div className="col-12 text-center ">
                                                    <GradientButton label="Submit & verify"
                                                        className={`padding-14px-tb ${style.e_submit_button}`}
                                                        loading={apiLoader}
                                                        onPress={_addBankAccount} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                    }

                </div>
            </div>
        </div>
    );
};


AddBankModal.defaultProps = {
    kycFlow: false,
    verifyUsingUPI: false,
    updateBank: () => {
        // Default function logic here
    }
}