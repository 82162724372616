
/*
 *   File : user-summary.js
 *   Author URI : https://evoqins.com
 *   Description : User detail
 *   Integrations : null
 *   Version : v1.1
 */
/*import packages*/
import { React, Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Icon } from "../Icon";
import { Modal } from "bootstrap";
import { UpdateRMModal } from "../Modal";


import APIService from "../../Services/api-service";
import { _getZone } from "../../Helper/api";

const UserDetailSummary = (props) => {
    const ADMIN_PROFILE_DATA = useSelector(state => state.MT_ADMIN_STORE.ADMIN_PROFILE);

    const [showRMModal, setShowRMModal] = useState(false);
    const [zoneOptions, setZoneOptions] = useState([]);
    const location = useLocation();

    // open block admin modal
    useEffect(() => {
        if (showRMModal === true) {
            const modal = new Modal(document.getElementById("update-rm"));
            modal.show();
        }
    }, [showRMModal]);

    const _closeEditModal = () => {
        setZoneOptions([]);
        setShowRMModal(false);
    }

    // API - get zone
    async function _getZoneOptions() {
        let data = await _getZone();
        const modified_data = data.map((item) => {
            return {
                value: item.id,
                label: item.name
            }
        })
        setZoneOptions(modified_data);
        setShowRMModal(true);;
    }


    return (
        <Fragment>
            <div className="border-radius-24px p-3 bg-dark-alice-blue border-all border-bright-gray">
                <div className="row">
                    <div className="col-md-4 ">
                        <div className="d-flex align-items-center">
                            <div className="  d-flex justify-content-center align-items-center ">
                                <p className="color-jett-black e-poppins-medium e-font-16 text-uppder-case mb-0 bg-water p-4 border-radius-100">
                                    {props.basicInfo.short_name}
                                </p>
                            </div>
                            <div className="ps-2 w-100">
                                <h6 className="color-jett-black e-font-16 e-poppins-medium line-height-24px mb-2">
                                    {props.basicInfo.name}
                                </h6>
                                {
                                    props.basicInfo.demat === true &&
                                    <p className="border-radius-16px color-polished-pine e-poppins-medium e-font-10 line-height-18px padding-6px-lr py-1 border-polished-pine border-all bg-pale-turquoise w-max-content">Demat</p>
                                }
                                <p className="e-font-14 e-poppins-medium line-height-14px color-gray my-0">
                                    Mastertrust ID:
                                    <span className="color-charleston-green ps-2">
                                        {props.basicInfo.mastertrust_id}
                                    </span>
                                </p>
                                <div className="d-flex align-items-center justify-content-between mt-3">
                                    {
                                        props.basicInfo.associate_name !== '-' ?
                                            <div className="d-flex">
                                                <p className="e-font-14 e-poppins-medium line-height-14px color-gray my-0">
                                                    AP:
                                                </p>
                                                <p className="color-charleston-green e-font-14 e-poppins-medium line-height-14px mb-0 ms-2">
                                                    {props.basicInfo.associate_name}
                                                </p>
                                            </div>
                                            :
                                            <div className="d-flex">
                                                <p className="e-font-14 e-poppins-medium line-height-14px color-gray my-0">
                                                    RM:
                                                </p>
                                                <p className="color-charleston-green e-font-14 e-poppins-medium line-height-14px mb-0 ms-2">
                                                    {props.basicInfo.rm_name}
                                                </p>
                                            </div>
                                    }

                                    {
                                        (ADMIN_PROFILE_DATA.admin_type_id === 1 || ADMIN_PROFILE_DATA.admin_type_id === 2) &&
                                        <p className='color-primary-color line-height-16px e-poppins-regular e-font-14  my-0 cursor-pointer'
                                            onClick={_getZoneOptions}>
                                            Edit
                                            <Icon icon="Pen-square"
                                                size={24}
                                                className="ms-1" />
                                        </p>
                                    }

                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-8 pt-md-0 pt-sm-3 pt-3 ">
                        <div className="bg-white p-3 border-radius-16px d-flex white-space-nowrap overflow-x-scroll">
                            <div className="border-right-1px border-bright-gray padding-40px-right">
                                <p className="e-font-14 e-poppins-medium line-height-16px color-outer-space my-0">
                                    Total invested
                                </p>
                                <h6 className="e-font-16 e-poppins-semi-bold line-height-16px color-light-black mt-2 mb-0">
                                    <span className="e-inter-semi-bold">₹</span>
                                    {props.summaryInfo.total_invested ? props.summaryInfo.total_invested.toLocaleString('en-IN') : 0}
                                </h6>
                            </div>
                            <div className="border-right-1px border-bright-gray padding-40px-lr">
                                <p className="e-font-14 e-poppins-medium line-height-16px color-outer-space my-0">
                                    Current value
                                </p>
                                <h6 className="e-font-16 e-poppins-semi-bold line-height-16px color-light-black mt-2 mb-0">
                                    <span className="e-inter-semi-bold">₹</span>
                                    {props.summaryInfo.total_current ? props.summaryInfo.total_current.toLocaleString('en-IN') : 0}
                                </h6>
                            </div>
                            <div className="border-right-1px border-bright-gray padding-40px-lr">
                                <p className="e-font-14 e-poppins-medium line-height-16px color-outer-space my-0">
                                    Returns
                                </p>
                                <h6 className="e-font-16 e-poppins-semi-bold line-height-16px color-light-black mt-2 mb-0">
                                    <span className="e-inter-semi-bold">₹</span>
                                    {props.summaryInfo.total_returns ? props.summaryInfo.total_returns.toLocaleString('en-IN') : 0}
                                </h6>
                            </div>
                            <div className="border-right-1px border-bright-gray padding-40px-lr">
                                <p className="e-font-14 e-poppins-medium line-height-16px color-outer-space my-0">
                                    Returns %
                                </p>
                                <h6 className={`${props.summaryInfo.total_returns_percentage < 0 ? 'color-red' : props.summaryInfo.total_returns_percentage > 0 ? 'color-sea-green' : 'color-outer-space'} e-font-16 e-poppins-semi-bold line-height-16px mt-2 mb-0`}>
                                    {props.summaryInfo.total_returns_percentage}
                                </h6>
                            </div>
                            <div className=" padding-40px-left">
                                <p className="e-font-14 e-poppins-medium line-height-16px color-outer-space my-0">
                                    XIRR
                                </p>
                                <h6 className={`${props.summaryInfo.xirr < 0 ? 'color-red' : props.summaryInfo.xirr > 0 ? 'color-sea-green' : 'color-light-black'} e-font-16 e-poppins-semi-bold line-height-16px mt-2 mb-0`}>
                                    {props.summaryInfo.xirr}%
                                </h6>
                            </div>

                        </div>

                        {

                            <div className="d-flex justify-content-end">
                                <p className="border-radius-24px bg-mint-ice color-black mt-1 me-2 e-poppins-medium e-font-12 mt-4 line-height-16px mb-0 py-2 px-3 white-space-nowrap h-max-content w-fit-content">{props.basicInfo.account_type}</p>

                                {
                                    props.basicInfo.is_deleted === true &&
                                    <div className="color-red-orange e-poppins-medium e-font-14 bg-soft-pink me-2 w-max-content mt-4 px-3 py-1 border-radius-24px ">
                                        <span className="mt-1">Deleted</span>
                                    </div>
                                }


                                {/* {
                                    props.basicInfo.signup_progress === "PIN_CONFIGURED" && 
                                        props.basicInfo.is_rekyc === true && //props.basicInfo.is_partially_imported === true && 
                                            <p className="border-radius-24px bg-mint-ice color-black mt-1 e-poppins-medium e-font-12 mt-4 line-height-16px mb-0 py-2 px-3 ms-2  white-space-nowrap h-max-content w-fit-content">
                                                Non-BSE-Incomplete
                                            </p>
                                } */}


                                {
                                    props.basicInfo.is_partially_imported === true && props.basicInfo.kyc_step >= 1 ?
                                        <>
                                            <p className="border-radius-24px bg-mint-ice color-black mt-1 e-poppins-medium e-font-12 mt-4 line-height-16px mb-0 py-2 px-3 ms-2  white-space-nowrap h-max-content w-fit-content">
                                                Non-BSE-Incomplete
                                            </p>
                                            <p className="border-radius-24px bg-mint-ice color-black mt-1 e-poppins-medium e-font-12 mt-4 line-height-16px mb-0 py-2 px-3 ms-2  white-space-nowrap h-max-content w-fit-content">
                                                KYC Inprogress
                                            </p>
                                        </>
                                        :
                                        <>
                                            {
                                                props.basicInfo.signup_progress &&
                                                <p className="border-radius-24px bg-mint-ice color-black mt-1 e-poppins-medium e-font-12 mt-4 line-height-16px mb-0 py-2 px-3  white-space-nowrap h-max-content w-fit-content">
                                                    {props.basicInfo.signup_progress.replace(/_/g, " ")}
                                                </p>
                                            }
                                            {
                                                props.basicInfo.is_rekyc &&
                                                <p className="border-radius-24px bg-mint-ice color-black mt-1 e-poppins-medium e-font-12 mt-4 line-height-16px mb-0 py-2 px-3 ms-2  white-space-nowrap h-max-content w-fit-content">
                                                    Re-KYC In progress
                                                </p>
                                            }
                                        </>
                                }
                            </div>
                        }

                    </div>

                </div>
            </div>

            {
                showRMModal === true ?
                    <UpdateRMModal customerId={location.state.user_id}
                        rmDetail={props.basicInfo}
                        zoneOptions={zoneOptions}
                        close={_closeEditModal}
                        updateDetail={() => { props.updateDetail() }} />
                    :
                    null
            }
        </Fragment>
    )

}

UserDetailSummary.defaultProps = {
    basicInfo: {
        name: "",
        total_invested: 0,
        current_value: 0,
        mastertrust_id: "",
        return: 0,
        returns: 0,
        xirr: 0,
        returns_percentage: 0
    }
}
export default UserDetailSummary;
